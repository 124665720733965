.route__contactUs {
    padding-top: 50px;
    padding-left: 310px;
    padding-right: 310px;

    h1 {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        line-height: 43px;
        text-align: center;
        letter-spacing: 0.05em;
        text-transform: capitalize;
        color: #001B57;
    }

    &__gridbox {
        margin-top: 56px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 30px;

        &__info {

            #map{
                width: 100%;
                margin-top: 20px;
            }

            #info{
                padding: 70px;
                display: grid;
                grid-template-columns: 1fr 1fr;
                background: #F8F9FA;
                border: 1px solid #EAEAEA;
                border-radius: 5px;
                row-gap: 20px;
                column-gap: 70px;
                height: 294px;
    
                div {
                    display: flex;
                    flex-direction: row;
    
                    img {
                        width: 20px;
                        height: 20px;
                        margin-right: 10px;
                    }
    
                    p {
                        margin-top: 12px;
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 140%;
                        color: #5E6366;
                    }
    
                    strong {
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 140%;
                        color: #001B57;
                    }
                }
            }
        }

        #form {
            display: flex;
            flex-direction: column;
            position: relative;

            #message-sent{
                position: absolute;
                background: #001c5711;
                width: 100%;
                height: 79%;
                animation: animateMsg .5s linear;

                @keyframes animateMsg{
                    0%{
                        opacity: 0;
                    }
                    100%{
                        opacity: 1;
                    }
                }

                h2{
                    font-size: 28px;
                    color: green;
                    text-align: center;
                    margin-top: 40%;
                }
            }

            p {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                letter-spacing: 0.02em;
                color: #6B7280;
            }

            input {
                margin-top: 16px;
                margin-bottom: 16px;
                width: 638px;
                height: 50px;
                background: #FFFFFF;
                border: 1px solid #9CA3AF;
                border-radius: 5px;
                padding-left: 20px;
                outline: none;
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                letter-spacing: 0.02em;
                color: #1F2937;
            }

            textarea {
                width: 638px;
                height: 180px;
                background: #FFFFFF;
                border: 1px solid #9CA3AF;
                border-radius: 5px;
                outline: none;
                padding: 20px;
                margin-top: 16px;
            }

            .sendBtn {
                width: 638px;
                height: 60px;
                background: #0458AD;
                border-radius: 3px;
                margin-top: 50px;
                font-family: 'Open Sans';
                font-weight: 700;
                font-size: 22px;
            }
        }
    }
}

@media screen and (max-width: 1700px){
    .route__contactUs{
        padding-left: 150px;
        padding-right: 150px;
    }
}

@media screen and (max-width: 1500px){
    .route__contactUs{
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media screen and (max-width: 1243px){
    .route__contactUs__gridbox{
        grid-template-columns: 1fr;
        place-items: center;
    }

}

@media screen and (max-width: 1107px){
    .route__contactUs__gridbox__info #info{
        padding: 20px;
    }
}

@media screen and (max-width: 910px){
    .route__contactUs__gridbox__info #info{
        row-gap: 0;
        column-gap: 0;
    }
}

@media screen and (max-width: 860px){
    .route__contactUs__gridbox__info{
        grid-template-columns: 1fr;
        width: 100%;
    }
    .route__contactUs__gridbox__info #info{
        height: fit-content;
        width: 100%;
    }
    .route__contactUs__gridbox #form{
        width: 100%;
    }
    .route__contactUs__gridbox__info #info{
        display: flex;
        flex-direction: column;
        justify-content: baseline;
        align-items: baseline;
    }
    .route__contactUs__gridbox__info #info div{
        margin-top: 15px;
    }
    .route__contactUs__gridbox #form input{
        width: 100%;
    }
    .route__contactUs__gridbox #form textarea{
        width: 100%;
    }
    .route__contactUs__gridbox #form .sendBtn{
        width: 100%;
    }
}