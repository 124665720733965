.route__userPanel {
    padding-left: 310px;
    padding-right: 310px;
    display: grid;
    grid-template-columns: 20% 80%;
    padding-top: 70px;
    min-height: fit-content;
    animation: animatePanel .5s linear;

    @keyframes animatePanel {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    #mobile-wel{
        display: none;
    }

    #hamburger {
        display: none;
    }

    #close {
        display: none;
    }

    &__navbar {

        #wel {
            display: flex;
            flex-direction: row;
            justify-content: baseline;
            align-items: center;

            p {
                margin-bottom: 0;
                margin-left: 22px;
            }

            img {
                width: 25px;
                height: 25px;
            }

            margin-bottom: 30px;

            #bigProfilePic {
                width: 30px;
                height: 30px;
            }
        }

        #curuser {
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
            letter-spacing: 0.05em;
            color: #001B57;
        }

        h1 {
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            font-family: 'Open Sans';
            color: #FFFFFF;
            margin-bottom: 30px;
        }

        p {
            margin-bottom: 24px;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 25px;
            color: #FFFFFF;
            cursor: pointer;
        }

        div {
            display: flex;
            flex-direction: row;
            justify-content: baseline;
            align-items: center;
            margin-bottom: 16px;
            background: #F8F9FA;
            border: 1px solid #EAEAEA;
            border-radius: 5px;
            padding: 15px;

            img {
                width: 20px;
                height: 20px;
            }

            p {
                margin-bottom: 0;
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 140%;
                color: #8A8C90;
                margin-left: 16px;
            }
        }
    }

    &__body {

        &__header {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            width: 100%;
            padding: 15px;
            background: #FFFFFF;
            box-shadow: 0px 0px 137px rgba(0, 0, 0, 0.24);

            .logoutBtn {
                height: fit-content;
                padding: 5px;
                margin-left: 10px;
            }
        }

        &__changePassword {
            padding-top: 60px;
            padding-left: 20px;
            animation: animatePassChange .5s linear;

            @keyframes animatePassChange {
                0% {
                    opacity: 0;
                }

                100% {
                    opacity: 1;
                }
            }

            div {
                background: #F8F9FA;
                border: 1px solid #EAEAEA;
                border-radius: 5px;
                padding: 20px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                span {
                    width: 48%;

                    p {
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 16px;
                        letter-spacing: 0.02em;
                        color: #6B7280;
                    }

                    #passMsg {
                        font-size: 15px;
                        font-weight: 700;
                        text-align: right;
                        margin-top: 10px;
                    }

                    input {
                        width: 100%;
                        margin-top: 12px;
                        background: #FFFFFF;
                        border: 1px solid #9CA3AF;
                        border-radius: 5px;
                        height: 40px;
                        padding-left: 19px;
                        outline: none;
                    }

                    .savePassBtn {
                        display: block;
                        margin-left: auto;
                        margin-right: 0;
                        margin-top: 20px;
                        width: 89px;
                        height: 39px;
                        background: #0458AD;
                        border-radius: 2px;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 19px;
                        text-align: center;
                        letter-spacing: 0.02em;
                        font-family: 'Open Sans';
                        color: #FFFFFF;
                    }
                }
            }
        }

        &__shippingDetails {
            animation: animateShipping .5s linear;

            @keyframes animateShipping {
                0% {
                    opacity: 0;
                }

                100% {
                    opacity: 1;
                }
            }

            &__details {
                background: white;
                width: 100%;
                padding: 20px;
                margin-top: 35px;
                margin-left: auto;
                margin-right: auto;
                border-radius: 5px;

                span {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;

                    h2 {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 22px;
                        line-height: 26px;
                        font-family: 'Open Sans';
                        color: #5E6366;
                    }

                    .editShippingBtn {
                        height: fit-content;
                        padding: 5px;
                    }
                }


                &__gridbox {
                    display: grid;
                    grid-template-columns: 1fr;
                    width: 100%;
                    gap: 20px;
                    margin-top: 10px;

                    p {
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 140%;
                        color: #5E6366;
                    }

                    strong {
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 140%;
                        color: #001B57;
                    }

                    div {
                        background: #F8F9FA;
                        border: 1px solid #EAEAEA;
                        border-radius: 5px;
                        width: 100%;
                        padding: 20px;

                        input {
                            animation: animateInput .5s linear;
                            outline: none;
                            padding: 3px;

                            @keyframes animateInput {
                                0% {
                                    max-width: 0px;
                                }

                                100% {
                                    max-width: 1000px;
                                }
                            }
                        }
                    }
                }
            }

            &__add {
                background: white;
                width: 100%;
                padding-left: 20px;
                padding-top: 40px;
                margin-top: 10px;
                margin-left: auto;
                margin-right: auto;
                border-radius: 5px;

                span {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;

                    h2 {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 22px;
                        line-height: 26px;
                        font-family: 'Open Sans';
                        color: #5E6366;
                    }

                    .saveShippingBtn {
                        height: fit-content;
                        padding: 5px;
                    }
                }

                p {

                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: 0.02em;
                    color: #6B7280;
                }

                &__gridbox {
                    display: grid;
                    grid-template-columns: 1fr;
                    width: 100%;
                    gap: 10px;
                    margin-top: 10px;

                    div {
                        background: #F8F9FA;
                        border: 1px solid #EAEAEA;
                        border-radius: 5px;
                        padding: 20px;
                    }

                    input {
                        width: 100%;
                        height: 40px;
                        background: #FFFFFF;
                        border: 1px solid #9CA3AF;
                        border-radius: 5px;
                        outline: none;
                        padding-left: 10px;
                        font-family: 'Open Sans';
                        font-size: 14pt;
                        margin-top: 5px;
                    }

                    p {
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 16px;
                        letter-spacing: 0.02em;
                        color: #6B7280;
                    }
                }
            }
        }

        &__payment {
            animation: animatePayment .5s linear;

            @keyframes animatePayment {
                0% {
                    opacity: 0;
                }

                100% {
                    opacity: 1;
                }
            }

            &__box {
                background: white;
                width: 100%;
                padding: 20px;
                margin-top: 20px;
                margin-left: auto;
                margin-right: auto;
                border-radius: 5px;

                span {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 20px;

                    h2 {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 22px;
                        line-height: 26px;
                        font-family: 'Open Sans';
                        color: #5E6366;
                    }

                    .savePaymentMethodBtn {
                        height: fit-content;
                        padding: 5px;
                    }
                }

                &__dropdown {
                    cursor: pointer;
                    background: rgb(4, 88, 173);
                    width: 200px;
                    display: block;
                    margin-left: 0;
                    margin-right: auto;
                    margin-top: 10px;
                    color: white;
                    padding: 5px;
                    position: relative;

                    p {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;

                        img {
                            width: 12px;
                        }
                    }

                    &__body {
                        flex-direction: column;
                        position: absolute;
                        z-index: 1;
                        background: #0458ad;
                        width: 100%;
                        left: 0;
                        padding: 5px;
                        border-radius: 0 0 5px 5px;
                        overflow: hidden;
                        animation: animateDropdown .5s linear;
                        cursor: default;

                        @keyframes animateDropdown {
                            0% {
                                max-height: 0;
                            }

                            100% {
                                max-height: 1000px;
                            }
                        }

                        p {
                            margin-top: 10px;
                            cursor: pointer;
                        }
                    }
                }

                h2 {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 22px;
                    line-height: 26px;
                    font-family: 'Open Sans';
                    color: #5E6366;
                    margin-top: 20px;
                }

                &__billingDetails {
                    display: grid;
                    grid-template-columns: 1fr;
                    width: 100%;
                    gap: 10px;

                    input {
                        width: 100%;
                        height: 40px;
                        background: #FFFFFF;
                        border: 1px solid #9CA3AF;
                        border-radius: 5px;
                        outline: none;
                        padding-left: 10px;
                        font-family: 'Open Sans';
                        font-size: 14pt;
                    }

                    p {
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 16px;
                        letter-spacing: 0.02em;
                        color: #6B7280;
                    }
                }

                #paymentMsg {
                    color: red;
                    font-family: 'Open Sans';
                    text-align: center;
                    margin-top: 10px;
                }

                &__billingDetailsPreview {
                    display: grid;
                    grid-template-columns: 1fr;
                    width: 100%;
                    gap: 20px;
                    margin-top: 10px;

                    p {
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 16px;
                        letter-spacing: 0.02em;
                        color: #5E6366;
                    }

                    strong {
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-size: 15px;
                        line-height: 16px;
                        letter-spacing: 0.02em;
                        color: rgb(0, 27, 87);
                        font-weight: 600;
                    }

                    div {
                        background: #F8F9FA;
                        border: 1px solid #EAEAEA;
                        border-radius: 5px;
                        padding: 20px;

                        input {
                            animation: animateInput .5s linear;
                            outline: none;
                            padding: 3px;

                            @keyframes animateInput {
                                0% {
                                    max-width: 0px;
                                }

                                100% {
                                    max-width: 1000px;
                                }
                            }
                        }
                    }
                }
            }
        }

        &__orders {
            padding-left: 20px;
            padding-top: 53px;
            animation: animateOrders .5s linear;

            @keyframes animateOrders {
                0% {
                    opacity: 0;
                }

                100% {
                    opacity: 1;
                }
            }

            &__header {
                h2 {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 22px;
                    line-height: 26px;
                    font-family: 'Open Sans';
                    color: #5E6366;
                }
            }
        }

        &__profileSettings {
            animation: animateProfileSettings .5s linear;

            @keyframes animateProfileSettings {
                0% {
                    opacity: 0;
                }

                100% {
                    opacity: 1;
                }
            }

            &__box {
                margin-top: 10px;
                min-width: 100%;
                background: white;
                width: fit-content;
                margin-left: auto;
                margin-right: auto;
                padding: 20px;
                border-radius: 5px;

                span {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    width: 100%;

                    input {
                        margin-left: 14px;
                        width: 95%;
                    }

                    #secondInput {
                        margin-left: 34px;
                        margin-top: 12px;
                    }

                    h2 {
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 21px;
                        line-height: 29px;
                        color: #000000;
                    }

                    .settingsBtn {
                        height: fit-content;
                        padding: 5px;
                        margin-right: 0;
                        margin-left: auto;
                    }

                    strong {
                        color: #001B57;
                        margin-left: 14px;
                    }

                    p {
                        color: #001B57;
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px !important;
                        line-height: 140%;
                        margin-left: 34px;
                        margin-top: 12px;
                    }
                }

                &__gridbox {
                    display: grid;
                    grid-template-columns: 1fr;
                    gap: 20px;

                    div {
                        background: #F8F9FA;
                        border: 1px solid #EAEAEA;
                        border-radius: 5px;
                        padding: 10px;
                        color: white;
                        position: relative;

                        p {
                            font-size: 12px;
                        }

                        strong {
                            font-weight: 700;
                        }

                        input {
                            animation: animateInput .5s linear;
                            outline: none;
                            padding: 3px;

                            @keyframes animateInput {
                                0% {
                                    max-width: 0px;
                                }

                                100% {
                                    max-width: 1000px;
                                }
                            }
                        }

                        #btn-wrapper {
                            padding: 0;

                            .chPassBtn {
                                width: fit-content;
                                height: fit-content;
                                padding: 3px;
                                margin-top: 2px;
                            }
                        }

                        #password-modal {
                            display: flex;
                            flex-direction: column;
                            position: absolute;
                            z-index: 1;
                            background: white;
                            box-shadow: 0 0 4px rgba(0, 0, 0, 0.24);
                            border-radius: 5px;
                            top: 30px;
                            right: -150px;
                            width: fit-content;
                            animation: animatePassModal .5s linear;

                            @keyframes animatePassModal {
                                0% {
                                    opacity: 0;
                                }

                                100% {
                                    opacity: 1;
                                }
                            }

                            input {
                                width: 100%;
                                margin-bottom: 10px;
                                outline: none;
                                padding: 3px;
                            }

                            .passModalBtn {
                                width: fit-content;
                                height: fit-content;
                                padding: 5px;
                                display: block;
                                margin-left: auto;
                                margin-right: auto;
                            }

                            #passMsg {
                                text-align: center;
                                margin-top: 10px;
                                font-family: 'Open Sans';
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1770px) {
    .route__userPanel {
        padding-left: 150px;
        padding-right: 150px;
    }
}

@media screen and (max-width: 1512px) {
    .route__userPanel {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media screen and (max-width: 1100px) {
    .route__userPanel {
        grid-template-columns: 30% 70%;
    }

    .route__userPanel__body__orders {
        padding: 20px;
    }
}

@media screen and (max-width: 920px) {
    .route__userPanel {
        padding-top: 0;
        grid-template-columns: 1fr;
        min-height: 0;
    }

    .route__userPanel__body__profileSettings__box{
        padding: 0;
    }

    .route__userPanel__navbar {
        height: fit-content;
        display: grid;
        grid-template-columns: 1fr;
        gap: 10px;

        h1 {
            margin-bottom: 0;
        }

        p {
            margin-bottom: 0;
        }
    }

    .route__userPanel__body {
        min-height: 1200px;
    }

    .route__userPanel #mobile-wel {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        img {
            width: 24px;
            height: 24px;
        }

        span {
            display: flex;
            flex-direction: row;
            justify-content: baseline;
            align-items: center;

            p {
                margin-left: 10px;
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 29px;
                letter-spacing: 0.05em;
                color: #001B57;
                font-family: 'Open Sans';
            }
        }
    }

    .route__userPanel__navbar #wel {
        display: none;
    }

    .route__userPanel__navbar {
        overflow: hidden;
        margin-top: 20px;
        display: none;
        animation: animateNavbar 1s linear;

        @keyframes animateNavbar {
            0% {
                max-height: 0;
            }

            100% {
                max-height: 1000px;
            }
        }
    }

    .route__userPanel {
        #hamburger {
            display: block;
            margin-left: 0;
            margin-right: auto;
            cursor: pointer;
        }
    }

}

@media screen and (max-width: 533px) {
    .route__userPanel__body__shippingDetails__details {
        width: 100%;
        padding: 0;
    }

    .route__userPanel__body__shippingDetails__details span h2{
        font-size: 17px;
    }

    .route__userPanel__body__payment__box span h2{
        font-size: 17px;
    }

    .route__userPanel__body__changePassword div{
        flex-direction: column;
    }
    .route__userPanel__body__changePassword div span{
        width: 100%;
        margin-bottom: 10px;
    }

    .route__userPanel__body__shippingDetails__details__gridbox {
        grid-template-columns: 1fr;
    }

    .route__userPanel__body__header {
        p {
            font-size: 12px;
        }
    }

    .route__userPanel__body__header .logoutBtn {
        width: fit-content;
    }

    .route__userPanel__body__payment__box {
        min-width: 100%;
        width: 100%;

        p {
            &:nth-child(2) {
                text-align: left;
            }
        }
    }

    .route__userPanel__body__payment__box__billingDetailsPreview {
        grid-template-columns: 1fr;
    }

    .route__userPanel__body__profileSettings__box__gridbox {
        grid-template-columns: 1fr;
    }

    .route__userPanel__body__profileSettings__box {
        min-width: 100%;
        width: 100%;
    }
}