.route__shoppingCart {
    width: 100%;
    padding-left: 310px;
    padding-right: 310px;

    &__gridbox {
        display: grid;
        grid-template-columns: 70% 30%;
        margin-top: 50px;

        &__paymentProcessed {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            animation: animatePayment .5s linear;

            @keyframes animatePayment {
                0% {
                    opacity: 0;
                }

                100% {
                    opacity: 1;
                }
            }

            h1 {
                font-family: 'Open Sans';
                color: #001B57;
            }

            img {
                width: 20%;
                margin-top: 20px;
            }

            .shoppingBtn {
                width: fit-content;
                padding-left: 5px;
                padding-right: 5px;
                margin-left: auto;
                margin-right: auto;
                display: block;
                margin-top: 20px;
                font-family: 'Open Sans';
                font-weight: 700;
            }
        }

        &__payment {
            animation: animatePayment .5s linear;

            @keyframes animatePayment {
                0% {
                    opacity: 0;
                }

                100% {
                    opacity: 1;
                }
            }

            #errmsg {
                color: red;
                font-size: 15px;
                text-align: center;
            }

            textarea {
                width: 50%;
                display: block;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 10px;
                height: 5rem;
                outline: none;
                border: 1px solid #9CA3AF;
                border-radius: 5px;
                padding: 10px;
            }

            span {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                strong {
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 22px;
                    color: #001B57;
                }

                p {
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 22px;
                    color: #001B57;
                }

                div {
                    border: 1px solid #001B57;
                    width: 25px;
                    height: 0px;
                    margin-left: 20px;
                    margin-right: 20px;
                }
            }

            h2 {
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 22px;
                font-family: 'Open Sans';
                letter-spacing: 0.02em;
                color: #001B57;
                margin-bottom: 20px;
            }

            p {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 0.02em;
                color: #6B7280;
            }

            &__dropdown {
                width: 412px;
                height: 40px;
                background: #FFFFFF;
                border: 1px solid #9CA3AF;
                border-radius: 5px;
                margin-top: 12px;
                margin-bottom: 20px;
                padding-left: 20px;
                padding-right: 20px;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                position: relative;

                p {
                    color: black;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;

                    img {
                        width: 14px;
                    }

                    cursor: pointer;
                }

                div {
                    position: absolute;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;
                    top: 40px;
                    background: white;
                    width: 100%;
                    left: 0;
                    padding-left: 20px;
                    padding-bottom: 20px;
                    box-shadow: 0 0 2px black;
                    animation: animateDropdown 1s linear;
                    overflow: hidden;

                    p {
                        margin-top: 10px;
                        transition: 400ms;
                        font-size: 12px;
                        font-family: 'Open Sans';

                        &:hover {
                            color: #001B57;
                        }
                    }
                }
            }

            &__form {
                display: grid;
                grid-template-columns: 1fr 1fr;

                p {
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: 0.02em;
                    color: #6B7280;
                }

                input {
                    width: 412px;
                    height: 40px;
                    background: #FFFFFF;
                    border: 1px solid #9CA3AF;
                    border-radius: 5px;
                    margin-top: 12px;
                    margin-bottom: 20px;
                    padding-left: 20px;
                    outline: none;

                    &::placeholder {
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 16px;
                        letter-spacing: 0.02em;
                        color: #292B30;
                    }
                }

                span {
                    display: grid;
                    grid-template-columns: 50% 50%;
                    width: 412px;

                    input {
                        width: 90%;
                    }

                    #year {
                        display: block;
                        margin-left: auto;
                        margin-right: 0;
                    }
                }
            }
        }

        &__shippingDetails {
            animation: animateShipping .5s linear;

            @keyframes animateShipping {
                0% {
                    opacity: 0;
                }

                100% {
                    opacity: 1;
                }
            }

            #msg {
                color: red;
                font-family: 'Open Sans';
                text-align: center;
                margin-bottom: 10px;
                font-weight: 700;
                font-size: 17px;
                animation: animateMsg 1s linear;

                @keyframes animateMsg {
                    0% {
                        opacity: 0px;
                    }

                    100% {
                        opacity: 1000px;
                    }
                }
            }

            h2 {
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 22px;
                font-family: 'Open Sans';
                letter-spacing: 0.02em;
                color: #001B57;
            }

            span {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                strong {
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 22px;
                    color: #001B57;
                }

                p {
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 22px;
                    color: #828282;
                }

                div {
                    border: 1px solid #001B57;
                    width: 25px;
                    height: 0px;
                    margin-left: 20px;
                    margin-right: 20px;

                    &:nth-child(4) {
                        border: 1px solid #5E6366;
                    }
                }
            }

            &__form {
                display: grid;
                grid-template-columns: 1fr 1fr;
                margin-top: 26px;

                div {
                    p {
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 16px;
                        letter-spacing: 0.02em;
                        color: #6B7280;
                    }

                    input {
                        width: 412px;
                        height: 40px;
                        background: #FFFFFF;
                        border: 1px solid #9CA3AF;
                        border-radius: 5px;
                        margin-top: 12px;
                        margin-bottom: 20px;
                        padding-left: 20px;
                        outline: none;

                        &::placeholder {
                            font-family: 'Open Sans';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 16px;
                            letter-spacing: 0.02em;
                            color: #292B30;
                        }
                    }
                }
            }
        }

        &__left {

            &__info {
                background: #F6F6F6;
                height: fit-content;
                margin-top: 40px;
                border-radius: 7px;
                padding: 40px;

                div {
                    display: grid;
                    grid-template-columns: 10% 90%;
                    margin-bottom: 20px;

                    span {
                        display: flex;
                        flex-direction: column;

                        strong {
                            font-style: normal;
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 19px;
                            letter-spacing: 0.02em;
                            font-family: 'Open Sans';
                            color: #001B57;
                            margin-top: 5px;
                            margin-bottom: 10px;
                        }

                        p {
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 17px;
                            letter-spacing: 0.02em;
                            color: #5E6366;
                            font-family: 'Open Sans';
                        }
                    }
                }
            }

            #emptyMessage {
                color: #EB5757;
                font-weight: 700;
                font-size: 25px;
                text-align: center;
                margin-top: 150px;
            }

            &__singleProduct {
                display: grid;
                grid-template-columns: 20% 80%;
                border-bottom: 1px solid #D1D5DB;
                padding-bottom: 20px;
                margin-top: 30px;
                height: 217px;

                img {
                    width: 80%;
                    height: 80%;
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                }

                &__body {
                    display: flex;
                    flex-direction: column;
                    justify-content: baseline;
                    align-items: baseline;
                    margin-left: 45px;

                    #quantity {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        width: 132px;
                        height: 40px;
                        border-radius: 5px;
                        border: 1px solid #001B57;
                        padding: 19px;

                        strong {
                            cursor: pointer;
                            margin-top: 0;
                            -webkit-user-select: none;       
                            -moz-user-select: none; 
                            -ms-user-select: none; 
                            user-select: none; 
                        }

                        p {
                            margin-top: 0;
                            -webkit-user-select: none;       
                            -moz-user-select: none; 
                            -ms-user-select: none; 
                            user-select: none; 
                        }

                        margin-top: 20px;
                        margin-bottom: 24px;
                    }

                    #shipping {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;

                        span {
                            display: flex;
                            align-items: center;
                            justify-content: baseline;

                            img {
                                width: 22px;
                                height: 16px;
                            }

                            #delIcon {
                                height: 22px;
                            }

                            strong {
                                margin-top: 0;
                                font-family: 'Open Sans';
                                font-style: normal;
                                font-weight: 600;
                                font-size: 18px;
                                line-height: 25px;
                                letter-spacing: 0.02em;
                                color: #001B57;
                                margin-left: 17px;
                            }

                            p {
                                margin-top: 0;
                                margin-left: 10px;
                            }

                            &:last-child {
                                cursor: pointer;
                            }
                        }
                    }

                    h2 {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 24px;
                        letter-spacing: 0.02em;
                        color: #001B57;
                        font-family: 'Open Sans';
                        text-transform: uppercase;
                    }

                    p {
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 17px;
                        letter-spacing: 0.02em;
                        color: #5E6366;
                        margin-top: 15px;
                    }

                    strong {
                        margin-top: 20px;
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 24px;
                        letter-spacing: 0.02em;
                        color: #001B57;
                    }
                }
            }
        }

        &__right {
            margin-left: 70px;

            &__box {
                width: 100%;
                background: #F6F6F6;
                border: 1px solid #EAEAEA;
                padding-top: 30px;
                padding-bottom: 30px;
                padding-left: 20px;
                padding-right: 20px;

                h3 {
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 24px;
                    letter-spacing: 0.02em;
                    color: #001B57;
                }

                span {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 25px;

                    strong {
                        color: #001B57;
                    }
                }

                &__products {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 25px;

                    span {
                        margin-top: 0;

                        strong {
                            margin-left: 10px;
                            color: #001B57;
                        }
                    }
                }

                &__cuponCode {
                    margin-top: 25px;

                    small {
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 16px;
                        letter-spacing: 0.02em;
                        color: #6B7280;
                    }

                    span {
                        margin-top: 12px;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;

                        .cuponCodeBtn {
                            width: 115px;
                            height: 40px;
                            border-radius: 3px;
                            font-family: 'Open Sans';
                            font-weight: 700;
                            font-size: 14px;
                        }

                        input {
                            background: #FFFFFF;
                            width: 175px;
                            height: 40px;
                            border: none;
                            outline: none;
                            margin-right: 18px;
                            padding: 10px;
                            font-size: 15px;
                        }
                    }
                }
            }
        }
    }

    &__continue {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 50px;

        .continueShopping{
            color: #374151;
            border-radius: 3px;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            width: 345px;
            height: 40px;
            font-family: 'Open Sans';
            margin-bottom: 40px;
        }

        .continueBtn {
            width: 345px;
            height: 40px;
            font-family: 'Open Sans';
            background: #0458AD;
            border-radius: 3px;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            color: #FFFFFF;
        }
    }

    &__continueToPayment {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 50px;

        .cancelOrderBtn {
            color: #374151;
            font-family: 'Open Sans';
            font-weight: 700;
            width: 200px;
            height: 40px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 40px;
        }

        .continueToPaymentBtn {
            width: 345px;
            height: 40px;
            font-family: 'Open Sans';
            font-weight: 700;
            border-radius: 3px;
        }
    }
}

@media screen and (max-width: 1760px) {
    .route__shoppingCart {
        padding-left: 150px;
        padding-right: 150px;
    }
}

@media screen and (max-width: 1530px) {
    .route__shoppingCart {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media screen and (max-width: 1265px) {
    .route__shoppingCart__gridbox__right {
        margin-left: 20px;
    }
    .route__shoppingCart__continue .continueBtn{
        width: 100%;
    }
    .route__shoppingCart__continue .continueShopping{
        width: 100%;
    }

    .route__shoppingCart__gridbox__right__box__cuponCode span input {
        width: 50%;
    }

    .route__shoppingCart__gridbox__left__singleProduct__body {
        margin-left: 0;
    }

    .route__shoppingCart__gridbox__left__singleProduct {
        grid-template-columns: 35% 65%;
        height: 300px;
    }

    .route__shoppingCart__gridbox__shippingDetails h2 {
        text-align: center;
        margin-top: 20px;
    }

    .route__shoppingCart__gridbox__payment__form {
        grid-template-columns: 1fr;
        place-items: center;
    }

    .route__shoppingCart__gridbox__payment__dropdown {
        margin-left: auto;
        margin-right: auto;
    }

    .route__shoppingCart__gridbox__payment h2 {
        text-align: center;
        margin-top: 20px;
    }

    .route__shoppingCart__gridbox__payment p:nth-child(3) {
        text-align: center;
    }
}

@media screen and (max-width: 1235px) {
    .route__shoppingCart__gridbox__shippingDetails__form {
        grid-template-columns: 1fr;
        place-items: center;
    }
}

@media screen and (max-width: 1071px){
    .route__shoppingCart__gridbox__left__info div{
        grid-template-columns: 15% 85%;
    }
}

@media screen and (max-width: 987px) {
    .route__shoppingCart__gridbox {
        grid-template-columns: 65% 35%;
    }

    .route__shoppingCart__gridbox__left__singleProduct {
        grid-template-columns: 35% 65%;
        height: 230px;
    }
}

@media screen and (max-width: 862px) {
    .route__shoppingCart__gridbox {
        grid-template-columns: 1fr;
    }

    .route__shoppingCart__gridbox__right {
        margin-top: 20px;
    }

    .route__shoppingCart__gridbox__left__singleProduct {
        height: 260px;
    }

    .route__shoppingCart__gridbox__left__singleProduct {
        display: flex;
        flex-direction: column;
        justify-content: baseline;
        align-items: baseline;
        height: fit-content;

        img {
            margin-bottom: 24px;
            width: 166px;
            height: 177px;
        }
    }

    .route__shoppingCart__gridbox__left__singleProduct__body {
        width: 100%;
    }

    .route__shoppingCart__gridbox__left__singleProduct__body #shipping span img {
        margin-bottom: 0;
    }
}

@media screen and (max-width: 565px){
    .route__shoppingCart__gridbox__left__info {
        padding: 20px;
    }
    .route__shoppingCart__gridbox__left__info div{
        grid-template-columns: 15% 85%;
    }
    .route__shoppingCart__gridbox__left__info div img{
        width: 30px;
        height: 30px;
    }
}

@media screen and (max-width: 451px) {
    .route__shoppingCart__continueToPayment {
        flex-direction: column;
        flex-direction: column-reverse;
    }

    .route__shoppingCart__continueToPayment .cancelOrderBtn {
        display: block;
        margin-left: auto;
        margin-right: 0;
    }

    .route__shoppingCart__gridbox__right {
        margin-left: 0;
    }

    .route__shoppingCart__gridbox__shippingDetails__form div input {
        width: 100%;
    }

    .route__shoppingCart__gridbox__shippingDetails__form {
        div {
            width: 100%;
        }
    }

    .route__shoppingCart__gridbox__shippingDetails h2 {
        margin-top: 20px;
    }

    .route__shoppingCart__gridbox__payment textarea {
        width: 100%;
    }

    .route__shoppingCart__gridbox__payment__form input {
        width: 100%;
    }

    .route__shoppingCart__gridbox__payment__form div {
        width: 100%;
    }

    .route__shoppingCart__gridbox__payment__dropdown {
        width: 300px;
        margin-left: 0;
        margin-right: auto;
    }

    .route__shoppingCart__gridbox__payment__form input {
        width: 100%;
    }

    .route__shoppingCart__gridbox__payment {
        width: 100%;
    }

    .route__shoppingCart__gridbox__payment__form input {
        width: 300px;
    }

    .route__shoppingCart__gridbox__payment__form span {
        width: 300px;
    }
}