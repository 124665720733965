.route__productPage {
    padding-top: 80px;
    padding-left: 310px;
    padding-right: 310px;

    &__opinions {
        background: #F6F6F6;
        height: 514px;
        width: 1300px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .splide {
            width: 1000px !important;
        }

        h1 {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 36px;
            line-height: 43px;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: #001B57;
        }

        &__item {
            width: 303px;
            height: 320px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding-left: 30px;
            padding-bottom: 41px;
            -moz-box-shadow: inset 0px 0px 200px black;
            -webkit-box-shadow: inset 0px 0px 200px black;
            box-shadow: inset 0px 0px 200px black;
            margin-left: 15px;

            h3 {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 700;
                font-size: 24px;
                line-height: 29px;
                letter-spacing: 0.05em;
                color: #FFFFFF;
                margin-bottom: 11px;
            }

            p {
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 24px;
                letter-spacing: 0.05em;
                font-family: 'Open Sans';
                color: #FFFFFF;
            }

            strong {
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 22px;
                font-family: 'Open Sans';
                letter-spacing: 0.05em;
                color: #FFFFFF;
                margin-top: 9px;
                margin-bottom: 2px;
            }

            h6 {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                letter-spacing: 0.05em;
                font-family: 'Open Sans';
                color: #FFFFFF;
            }
        }
    }

    &__description {
        padding-top: 100px;
        padding-bottom: 40px;

        &__tabs {
            display: flex;
            flex-direction: column;
            justify-content: baseline;
            align-items: baseline;

            &__nav {
                display: flex;
                flex-direction: row;
                justify-content: baseline;
                align-items: center;

                div {
                    margin-right: 14px;
                    width: 185px;
                    height: 50px;
                    background: #ECECEC;
                    border-radius: 2px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;

                    p {
                        font-style: normal;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 19px;
                        letter-spacing: 0.02em;
                        text-transform: uppercase;
                        font-family: 'Open Sans';
                        color: #001B57;
                    }
                }
            }

            &__tab {
                background: #F6F6F6;
                border-radius: 7px;
                width: 100%;
                height: fit-content;
                padding: 50px;
                margin-top: 10px;

                div {
                    animation: animateTab .3s linear;

                    @keyframes animateTab {
                        0% {
                            opacity: 0;
                        }

                        100% {
                            opacity: 1000px;
                        }
                    }

                    h1 {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 30px;
                        line-height: 36px;
                        font-family: 'Open Sans';
                        letter-spacing: 0.02em;
                        color: #001B57;
                        margin-bottom: 14px;
                        text-transform: uppercase;
                    }

                    p {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 24px;
                        font-family: 'Open Sans';
                        letter-spacing: 0.02em;
                        color: #5E6366;
                    }

                    h3 {
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 22px;
                        letter-spacing: 0.02em;
                        color: #001B57;
                        margin-top: 26px;
                    }

                    #cert {
                        display: flex;
                        flex-direction: row;
                        justify-content: baseline;
                        align-items: center;
                        gap: 18px;
                        margin-top: 20px;
                    }

                    .ingredients__table {
                        width: 555px;
                        height: fit-content;

                        &__heading {
                            display: grid;
                            grid-template-columns: 50% 50%;
                            height: 37px;
                            box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);

                            div {
                                background: #0458AD;
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                                margin-right: 1px;

                                p {
                                    font-family: 'Open Sans';
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 14px;
                                    line-height: 17px;
                                    text-align: center;
                                    letter-spacing: 0.02em;
                                    color: #FFFFFF;
                                }
                            }
                        }

                        &__row {
                            display: grid;
                            grid-template-columns: 50% 50%;
                            min-height: 37px;
                            height: fit-content;

                            div {
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                                background: #FFFFFF;
                                box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
                                margin-right: 1px;

                                p {
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 14px;
                                    line-height: 17px;
                                    font-family: 'Open Sans';
                                    text-align: center;
                                    letter-spacing: 0.02em;
                                    color: #5E6366;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &__gridbox {
        display: grid;
        grid-template-columns: 50% 50%;
        height: 900px;
        gap: 50px;
        position: relative;

        #discount {
            position: absolute;
            top: 13px;
            left: 180px;
            width: 106px;
            height: 106px;
            background: #EB5757;
            border-radius: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-family: 'Open Sans';

            p {
                font-style: normal;
                font-weight: 700;
                font-size: 35.1863px;
                line-height: 42px;
                letter-spacing: 0.02em;
                color: #FFFFFF;
            }
        }

        #imgbox {
            display: flex;

            #sidebar {
                display: flex;
                flex-direction: column;
                margin-right: 16px;

                img {
                    width: 150px;
                    height: 150px;
                    margin-bottom: 16px;
                    cursor: pointer;
                }
            }

            #prodimg {
                width: 505px;
                height: 590px;
            }
        }

        #form{
            display: flex;
            flex-direction: column;
            gap: 8px;
            width: 100%;
            max-width: 360px;
            &>input{
                padding-left: 10px;
                height: 42px;
                width: 100%;
                border: 1px solid #cfcfcf;
                border-radius: 5px;
                font-size: 16px;
                outline: none;
            }
            &>button{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 62px;
                border-radius: 5px;
                font-family: Merriweather, serif;
                font-size: 16px;
                background-color: #0C6CDA;
                transition: background-color 0.3s;
                cursor: pointer;
                line-height: 22px;
                color: #fff;
                font-weight: 700;
                transition: 0.3s ease;
                &:hover{
                    background-color: #0b5bb8;
                }
            }
        }

        div {

            h2 {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 26px;
                line-height: 31px;
                letter-spacing: 0.02em;
                color: #001B57;
                text-transform: uppercase;
            }

            p {
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 24px;
                letter-spacing: 0.02em;
                color: #5E6366;
                margin-top: 15px;
                margin-bottom: 20px;

                &:nth-child(4) {
                    font-size: 18px;
                }
            }

            #oldprice {
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 24px;
                letter-spacing: 0.02em;
                text-decoration-line: line-through;
                color: #EB5757;
                font-family: 'Open Sans';
            }

            span {
                display: flex;
                flex-direction: row;
                justify-content: baseline;
                align-items: center;
                margin-bottom: 20px;

                img {
                    width: 16px;
                    height: 16px;
                    margin-right: 10px;
                }

                p {
                    margin-top: 0;
                    margin-bottom: 0;
                    font-size: 20px;
                }
            }

            #quantity {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 132px;
                height: 40px;
                border: 1px solid #001B57;
                padding: 19px;
                margin-top: 20px;
                margin-bottom: 24px;

                strong {
                    cursor: pointer;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                }

                p {
                    font-size: 16px;
                    margin-top: 0;
                    margin-bottom: 0;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                }
            }

            .addToBasketBtn {
                width: 462px;
                height: 66px;
                background: #0458AD;
                border-radius: 3px;
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 22px;
                letter-spacing: 0.02em;
                text-transform: uppercase;
                color: #FFFFFF;
            }

            #delivery {
                width: 359px;
                height: 104px;
                background: #F6F6F6;
                padding: 22px;
                margin-bottom: 16px;

                span {
                    display: flex;
                    flex-direction: row;
                    justify-content: baseline;
                    align-items: center;
                    gap: 19px;

                    img {
                        width: 22px;
                        height: 16px;
                    }

                    &:last-child {
                        img {
                            width: 21px;
                            height: 22px;
                        }
                    }
                }
            }

            #shipping {
                display: flex;
                width: 580px;
                margin-top: 20px;
                background: #F6F6F6;
                border-radius: 7px;
                padding: 30px;

                img {
                    width: 22px;
                    height: 16px;
                    margin-top: 20px;
                }

                p {
                    display: flex;
                    flex-direction: column;
                    margin-left: 17px;
                }
            }
        }
    }

    #recommended {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        line-height: 43px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #001B57;
        text-align: center;
        margin-top: 70px;
    }

    .splide__arrow--prev svg,
    .splide__arrow svg {
        fill: black !important;
    }

    .splide__pagination__page {
        display: none !important;
    }

    .splide {
        padding: 0 !important;
        margin-left: 0 !important;
        margin-top: 40px !important;
    }
}

@media screen and (max-width: 1884px) {
    .route__productPage {
        padding-left: 150px;
        padding-right: 150px;
    }

    .route__productPage__opinions {
        width: 100%;
    }
}

@media screen and (max-width: 1558px) {
    .route__productPage .splide {
        width: 98% !important;
    }

    .route__productPage__gridbox {
        gap: 0;
    }

    .route__productPage {
        padding-left: 20px;
        padding-right: 20px;
    }

    .route__productPage__opinions .splide {
        width: 1000px !important;
    }
}

@media screen and (max-width: 1409px) {
    .route__productPage__gridbox {
        grid-template-columns: 55% 45%;
    }
}

@media screen and (max-width: 1323px) {
    .route__productPage__gridbox img {
        width: 90%;
        height: auto;
    }
}

@media screen and (max-width: 1284px) {
    .route__productPage__gridbox #imgbox #prodimg {
        width: 310px;
        height: 350px;
    }

    .route__productPage__gridbox {
        grid-template-columns: 40% 60%;
    }
}

@media screen and (max-width: 1245px) {
    .route__productPage__gridbox {
        grid-template-columns: 1fr;
    }

    .route__productPage__gridbox {
        height: fit-content;
    }
}

@media screen and (max-width: 1189px) {
    .route__productPage {
        padding-left: 20px;
        padding-right: 20px;
    }

    .route__productPage__opinions .splide {
        width: 650px !important;
    }

    .route__productPage .splide__arrow svg {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .route__productPage__gridbox div #shipping {
        width: 100%;
    }

    .route__productPage__gridbox div #shipping p {
        width: 100%;
    }

    .route__productPage__gridbox img {
        height: 80%;
    }

    .route__productPage__gridbox div .addToBasketBtn {
        width: 100%;
    }
}

@media screen and (max-width: 817px) {
    .route__productPage__gridbox img {
        height: 300px;
        width: 260px;
        margin-bottom: 20px;
    }

    .route__productPage__description__tabs {
        width: 100%;
    }

    .route__productPage__description__tabs__nav {
        width: 100%;
    }

    .route__productPage #recommended {
        margin-top: 40px !important;
    }

    .route__productPage__gridbox div span img {
        margin-left: 0;
        margin-bottom: 0;
    }

    .route__productPage__gridbox {
        grid-template-columns: 1fr
    }

    .route__productPage__description__tabs__nav div {
        width: 171px;
    }

    .route__productPage #recommended {
        font-size: 25px;
    }
}

@media screen and (max-width: 768px) {
    .route__productPage__description__tabs__nav {
        flex-direction: column;
    }

    .route__productPage__description__tabs__nav div {
        width: 100%;
        margin-bottom: 14px;
        margin-right: 0;
    }

    .route__productPage__opinions__item {
        margin-left: auto;
        margin-right: auto;
    }

    .route__productPage__description__tabs__tab div #cert {
        #tuh {
            width: 77px;
            height: 40px;
        }

        #circle {
            width: 47px;
            height: 47px;
        }
    }
}

@media screen and (max-width: 695px) {
    .route__productPage__description__tabs__tab div .ingredients__table {
        width: 100%;
    }
}

@media screen and (max-width: 640px) {
    .route__productPage__opinions .splide {
        width: 325px !important;
    }

    .route__productPage__opinions h1 {
        font-size: 25px;
    }
}

@media screen and (max-width: 512px) {

    .route__productPage__gridbox #imgbox {
        flex-direction: column;
    }

    .route__productPage__gridbox img {
        margin-left: auto;
        margin-right: auto;
    }

    .route__productPage__gridbox #imgbox #sidebar {
        flex-direction: row;
        justify-content: flex-start;
        align-items: baseline;
    }

    .route__productPage__gridbox #imgbox #sidebar img {
        width: 70px;
        height: 70px;
        margin-left: 0;
        margin-right: 16px;
    }

    .route__productPage__gridbox #discount {
        left: -10px;
        top: 80px;
    }

    .route__productPage {
        padding-top: 0;
    }

    .route__productPage__gridbox div p {
        font-size: 16px;
    }

    .route__productPage__gridbox div span p {
        font-size: 16px;
    }

    .route__productPage__description {
        padding-top: 50px;
    }

    .route__productPage__description__tabs__tab div p {
        font-size: 16px;
    }

    .route__productPage__description__tabs__tab {
        padding: 20px;
    }
}

@media screen and (max-width: 410px) {
    .route__productPage__opinions h1 {
        font-size: 23px;
    }

    .route__productPage__gridbox div #delivery{
        width: 100%;
    }
}