.route__landingPage {
    width: 100%;

    &__newsletter {
        padding-left: 310px;
        padding-right: 310px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 2fr;
        gap: 30px;
        place-items: center;
        height: fit-content;
        background: #D1EEEF;
        margin-top: 103px;
        margin-bottom: 103px;
        position: relative;

        #errmsg{
            color: red;
            font-size: 14px;
            margin-top: 5px;
        }

        &__subscribed{
            animation: animateMsg .5s linear;
            @keyframes animateMsg{
                0%{
                    opacity: 0;
                }
                100%{
                    opacity: 1;
                }
            }
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.548);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            p{
                margin-left: auto;
                margin-right: auto;
                color: green;
                text-align: center;
                font-weight: 700;
                margin-top: 5%;
                font-size: 20px;
                background: white;
                padding: 3px;
                border-radius: 5px;
                width: fit-content;
            }
        }

        #rotatedtext {

            h1,
            p {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 700;
                font-size: 73.8717px;
                color: #27447F;
                transform: rotate(-15deg);
            }

            p {
                font-size: 25px;
                text-align: center;
            }
        }

        &>p {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.02em;
            color: #27447F;
        }

        div {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            #inputbox {
                background: white;
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                input {
                    border: none;
                    outline: none;
                    padding-left: 5px;
                    background: #FFFFFF;
                    border-radius: 5px !important;
                }
            }

            span {
                display: flex;
                flex-direction: row;
                justify-content: baseline;
                align-items: center;
                margin-top: 5px;

                p {
                    width: 100%;
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 11px;
                    line-height: 15px;
                    letter-spacing: 0.02em;
                    color: #27447F;
                    margin-left: 5px;
                }
            }
        }
    }

    &__popup {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.411);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        animation: animatePopup .5s linear;

        @keyframes animatePopup {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }

        div {
            background: white;
            width: fit-content;
            height: fit-content;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 20px;
            border-radius: 10px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 20%;
            box-shadow: 0 0 4px gray;

            #close {
                margin-right: 0;
                margin-left: auto;
                cursor: pointer;
            }

            #prodimg {
                width: 200px;
                height: 200px;
                margin-top: 10px;
                margin-bottom: 10px;
            }

            strong {
                color: #001B57;
                text-align: center;
            }

            span {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 30%;
                margin-top: 10px;

                #old {
                    text-decoration: line-through;
                    font-size: 14px;
                }

                #new {
                    font-weight: 700;
                    color: #001B57;
                }
            }

            #btns {
                width: 100%;

                .btn {
                    width: 150px;
                    padding-left: 5px;
                    padding-right: 5px;
                }
            }
        }
    }

    &__sectionOne {
        width: 100%;
        display: grid;
        grid-template-columns: 60% 40%;
        margin-top: 50px;
        padding-left: 310px;
        padding-right: 310px;

        &__bigItem {
            position: relative;

            #kutija {
                position: absolute;
                right: 0;
                top: 45px;
            }

            #bg {
                width: 95%;
                height: 100%;
            }

            #secOne-img-box {
                position: absolute;
                top: 120px;
                bottom: 83px;
                left: 110px;
                color: #1D1F49;
                font-family: 'Lato';

                p {
                    text-align: center;
                }

                h1 {
                    font-size: 30px;
                    text-align: center;

                    span {
                        font-size: 40px;
                    }
                }

                .secOneFirstBtn {
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                    margin-top: 25px;
                    width: 205px;
                    height: 48px;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 24px;
                    color: #FFFFFF;
                    font-family: 'Open Sans';
                }
            }
        }

        &__smallItem {
            position: relative;
            width: 100%;

            #bg {
                width: 100%;
                height: 100%;
            }

            #natura {
                position: absolute;
                top: 83px;
                bottom: 44px;
            }

            #cellu {
                position: absolute;
                top: 82px;
                bottom: 44px;
                left: 119px;
                right: 205px;
            }

            #secOne-img-box {
                position: absolute;
                top: 128px;
                right: 45px;
                bottom: 105px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                p {
                    margin-bottom: 10px;
                }

                .secOneSecondBtn {
                    margin-top: 37px;
                    width: 182px;
                    height: 51px;
                    font-size: 18px;
                    font-family: 'Open Sans';
                }
            }
        }
    }

    &__sectionTwo {
        padding-left: 250px;
        padding-right: 250px;
        padding-top: 70px;

        h1 {
            text-align: center;
            font-size: 36px;
            font-family: 'Open Sans';
            color: #001B57;
        }

        &__slider {
            .splide__arrow--prev{
                left: 4em;
            }

            .splide__arrow--prev svg,
            .splide__arrow svg {
                fill: black !important;
            }

            .splide__arrow--prev svg {
                margin-left: -170px;
            }

            .splide__arrow--next{
                right: 1em;
            }

            .splide__pagination__page {
                display: none !important;
            }

            .splide__slide {
                display: grid !important;
                grid-template-columns: 1fr 1fr 1fr 1fr !important;

                #box {
                    margin-left: 20px;
                }
            }

            .splide {
                padding: 0 !important;
                margin-left: 0 !important;
                margin-top: 40px !important;
            }
        }
    }

    &__sectionThree {
        padding-left: 310px;
        padding-right: 310px;
        padding-top: 56px;
        position: relative;

        &__textbox {
            width: 345px;
            position: absolute;
            top: 220px;
            left: 620px;

            p {
                margin-top: 14px;
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 0.02em;
                color: #5E6366;
            }

            h1 {
                margin-bottom: 10px;
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 700;
                font-size: 28px;
                line-height: 38px;
                letter-spacing: 0.05em;
                text-transform: capitalize;
                color: #0A7929;
            }
        }
    }

    &__sectionFour {
        margin-top: 70px;

        h1 {
            text-align: center;
            font-style: normal;
            font-weight: 600;
            font-size: 36px;
            line-height: 43px;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            font-family: 'Open Sans';
            color: #001B57;
        }

        &__gridbox {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 25px;
            margin-top: 50px;
            padding-left: 310px;
            padding-right: 310px;

            &__item {
                img {
                    width: 100%;
                    height: auto;
                }

                p {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 28px;
                    line-height: 34px;
                    text-align: center;
                    letter-spacing: 0.05em;
                    color: #001B57;
                    font-family: 'Open Sans';
                }
            }
        }
    }

    &__sectionFive {
        padding-left: 310px;
        padding-right: 310px;
        padding-top: 70px;

        h1 {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 36px;
            line-height: 43px;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: #001B57;
            text-align: center;
        }

        p {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 27px;
            letter-spacing: 0.05em;
            color: #5E6366;
            text-align: center;
            margin-top: 15px;
        }

        &__gridbox {
            margin-top: 34px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;

            &__item {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                strong {
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 29px;
                    text-align: center;
                    letter-spacing: 0.05em;
                    color: #001B57;
                    margin-top: 22px;
                }

                p {
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 22px;
                    text-align: center;
                    letter-spacing: 0.05em;
                    color: #5E6366;
                    margin-top: 18px;
                }
            }
        }
    }
}

@media screen and (max-width: 1882px){
    #secthreepic {
        width: 100%;
    }
}

@media screen and (max-width: 1760px) {
    .route__landingPage__sectionTwo__slider .splide {
        width: 100% !important;
    }

    .route__landingPage__sectionTwo__slider .splide__slide {
        gap: 10px !important;
    }

    .route__landingPage__sectionFour__gridbox {
        padding-left: 150px;
        padding-right: 150px;
    }

    .route__landingPage__newsletter {
        padding-left: 20px;
        padding-right: 20px;
    }

    .route__landingPage__sectionFour__gridbox__item {
        display: grid;
        grid-template-columns: 1fr;
        place-items: center;
    }

    .route__landingPage__sectionTwo {
        padding-left: 150px;
        padding-right: 150px;
    }

    .route__landingPage__sectionThree {
        padding-left: 150px;
        padding-right: 150px;
    }

    .route__landingPage__sectionFive {
        padding-left: 150px;
        padding-right: 150px;
    }
}

@media screen and (max-width: 1744px) {
    .route__landingPage__sectionTwo {
        padding-left: 50px;
        padding-right: 50px;
    }

    .route__landingPage__sectionThree {
        padding-left: 20px;
        padding-right: 20px;
    }

    .route__landingPage__sectionThree__textbox {
        left: 400px;
        top: 250px;
    }

    .route__landingPage__sectionFour__gridbox {
        padding-left: 20px;
        padding-right: 20px;
    }

    .route__landingPage__sectionFive {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media screen and (max-width: 1320px) {
    .route__landingPage__sectionThree__textbox {
        left: 350px;
        top: 220px;
    }

    .route__landingPage__sectionThree__textbox {
        left: 300px;
        top: 190px;
    }

    .route__landingPage__sectionTwo__slider .splide__slide {
        grid-template-columns: 1fr 1fr 1fr 1fr !important;
    }

    .route__landingPage__sectionThree {
        display: none;
    }
}

@media screen and (max-width: 1154px) {
    .route__landingPage__sectionFour__gridbox {
        grid-template-columns: 1fr 1fr;
    }

    .route__landingPage__sectionFour__gridbox__item img {
        width: 70%;
    }

    .route__landingPage__popup div {
        margin-top: 35%;
    }
    .route__landingPage__newsletter{
        grid-template-columns: 1fr 1fr;
        padding-bottom: 30px;
    }
}

@media screen and (max-width: 854px) {
    .route__landingPage__sectionThree__textbox {
        left: 230px;
        top: 160px;
    }

    .component__footer__contact p {
        text-align: center;
    }

    .route__landingPage__sectionTwo {
        padding-top: 0;
        padding-left: 50px;
        padding-right: 50px;
    }
}

@media screen and (max-width: 572px) {
    .route__landingPage__newsletter > p{
        width: 70%;
    }
    .route__landingPage__newsletter{
        gap: 0;
    }
    .route__landingPage__sectionTwo__slider{
        margin-left: 20px;
    }
    .route__landingPage__sectionTwo__slider .splide__arrow--next{
        right: -1em;
    }
    .route__landingPage__sectionFive{
        padding-top: 0;
    }
    #rotatedtext{
        position: absolute;
        top: 20px;
        left: 20px;
    }
    #rotatedtext h1{
        font-size: 33px !important;
    }
    .route__landingPage__newsletter #rotatedtext p{
        font-size: 18px;
    }
    .route__landingPage__sectionFive h1{
        font-size: 25px;
    }
    #newsletter-pic{
        width: 105px;
        height: 85px;
    }
    .route__landingPage__sectionFour__gridbox {
        grid-template-columns: 1fr;
    }

    .route__landingPage__sectionFive__gridbox {
        grid-template-columns: 1fr;
        gap: 20px;
    }

    .route__landingPage__sectionTwo h1 {
        font-size: 25px;
    }

    .route__landingPage__sectionFour__gridbox {
        grid-template-columns: 1fr;
    }

    .route__landingPage__popup div {
        margin-top: 35%;
    }

    .route__landingPage__popup div {
        margin-top: 55%;
    }

    .route__landingPage__newsletter{
        grid-template-columns: 1fr;
        padding-top: 30px;
        position: relative;
    }
    .route__landingPage__newsletter div span p{
        width: 90%;
    }
}

@media screen and (max-width: 420px) {
    .route__landingPage__sectionTwo h1{
        font-size: 20px;
    }
    .route__landingPage__popup div {
        width: 95%;
        margin-top: 65%;
    }
    .route__landingPage__sectionTwo{
        padding-left: 20px;
        padding-right: 20px;
    }
    .route__landingPage__sectionTwo__slider .splide__arrow--next{
        right: -1em;
    }
}