#mobileSearch {
    display: none;
}

.component__header {
    max-width: 100%;
    height: fit-content;
    font-family: 'Open Sans', sans-serif;
    background: white;
    position: relative;

    #logomob {
        display: none;
    }

    #hamburger {
        display: none;
    }

    &__userModal {
        position: absolute;
        top: 0;
        width: 100%;
        height: 1000%;
        z-index: 2;
        background: rgba(0, 0, 0, 0.35);
        animation: animateUserModal .5s linear;

        @keyframes animateUserModal {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }

        &__box {
            background: white;
            width: fit-content;
            padding: 20px;
            box-shadow: 0 0 4px #5E6366;
            display: block;
            margin-left: auto;
            margin-right: auto;
            margin-top: 400px;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            animation: animateBox .5s linear;

            @keyframes animateBox {
                0% {
                    opacity: 0;
                }

                100% {
                    opacity: 1;
                }
            }

            img {
                display: block;
                margin-left: auto;
                margin-right: 0;
                cursor: pointer;
            }

            h2 {
                font-family: 'Open Sans';
                font-size: 20px;
                margin-bottom: 20px;
                color: #27447F;
                text-align: center;
            }

            input {
                font-family: 'Open Sans';
                font-size: 15px;
                margin-bottom: 10px;
                outline: none;
                margin-top: 5px;
                height: 40px;
                width: 300px;
                padding: 5px;
                background: #FFFFFF;
                border: 1px solid #9CA3AF;
                border-radius: 5px;
            }

            p {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 0.02em;
                color: #6B7280;
                width: 300px;

                &:nth-child(8) {
                    text-align: center;
                }

                span {
                    color: #001B57;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }

            .loginBtn {
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 10px;
                width: 100%;
            }
        }
    }

    &__top {
        display: grid;
        grid-template-columns: 0.7fr 3fr 1fr 0.2fr 0.2fr;
        place-items: center;
        padding-left: 310px;
        padding-right: 310px;
        padding-top: 40px;
        padding-bottom: 30px;

        &__languageIcon {
            position: absolute;
            right: 20px;
            top: 10px;
            display: flex;
            flex-direction: row;
            justify-content: baseline;
            align-items: center;
            cursor: pointer;

            div {
                position: absolute;
                top: 30px;
                border-radius: 0 0 5px 5px;
                width: 100%;
                background: white;
                right: 0;
                padding: 5px;
                border-top: 0px;
                border-left: 1px solid gray;
                border-right: 1px solid gray;
                border-bottom: 1px solid gray;

                p {
                    margin-bottom: 10px;
                    color: #001B57;
                    font-size: 14px;
                }

                overflow: hidden;
                z-index: 5;

                animation: animateLangDropdown .5s linear;

                @keyframes animateLangDropdown {
                    0% {
                        max-height: 0;
                    }

                    100% {
                        max-height: 1000px;
                    }
                }
            }

            img {
                width: 24px;
                height: 24px;
            }

            span {
                display: flex;
                flex-direction: row;
                justify-content: baseline;
                align-items: center;
                margin-left: 13px;

                p {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 22px;
                    font-family: 'Open Sans';
                    letter-spacing: 0.05em;
                    color: #001B57;
                }

                img {
                    width: 12px;
                    height: 7px;
                }
            }
        }

        &__nav {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-left: 20px;

            p {
                font-size: 16px;
                font-weight: 600;
                margin-right: 30px;
                cursor: pointer;
                color: #5E6366;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        &__search {
            width: 340px;
            height: 40px;
            margin-right: 50px;
            outline: none;
            border: 1px solid #5E6366;
            border-radius: 5px;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            letter-spacing: 0.02em;
            color: #9CA3AF;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding-left: 15px;
            padding-right: 15px;
            position: relative;

            img {
                width: 18px;
                height: 18px;
                position: absolute;
                right: 15px;
            }

            input {
                border: none;
                width: 100%;
                height: 100%;
                outline: none;
            }

            &__results {
                position: absolute;
                top: 40px;
                left: 0;
                background: #F5F5F5;
                width: 100%;
                color: black;
                z-index: 2;
                border: 1px solid #5E6366;
                border-radius: 5px;
                overflow-y: scroll;
                max-height: 300px;
                padding-top: 10px;
                animation: animateResults .3s linear;

                @keyframes animateResults {
                    0% {
                        max-height: 0;
                    }

                    100% {
                        max-height: 300px;
                    }
                }

                #result-item {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    height: 50px;
                    margin-bottom: 10px;
                    transition: 400ms;
                    padding: 10px;
                    cursor: pointer;

                    img {
                        width: 50px;
                        height: 50px;
                        position: static;
                        margin-right: 10px;
                    }

                    &:hover {
                        box-shadow: 0 0 4px gray;
                    }
                }
            }
        }

        &__cart {
            position: relative;
            padding-right: 20px;
            cursor: pointer;

            #cart-num {
                width: 20px;
                height: 20px;
                position: absolute;
                top: 0;
                right: 0;
                background: #EB5757;
                padding: 1px;
                border-radius: 50%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                p {
                    text-align: center;
                    margin-right: 0;
                    color: white;
                    font-size: 14px;
                }
            }
        }

        #user {
            width: 35px;
            height: 35px;
            cursor: pointer;
            margin-left: 10px;
        }
    }

    &__bottom {
        width: 100%;
        padding-left: 310px;
        padding-right: 310px;
        background: #405481;
        ;
        color: white;
        padding-top: 14px;
        padding-bottom: 14px;

        &__nav {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            div {
                display: flex;
                flex-direction: row;
                align-items: center;

                p {
                    margin-left: 0;
                }

                span {
                    margin-left: 6px;
                    padding-left: 20px;
                    padding-right: 20px;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    background: #EB5757;
                    color: white;
                }
            }

            p {
                margin-left: 50px;
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 0.02em;
                cursor: pointer;
                &:first-child{
                    margin-left: 0;
                }
            }
        }
    }
}

@media screen and (max-width: 1850px) {
    .component__header__top {
        padding-left: 280px;
        padding-right: 280px;
    }
}

@media screen and (max-width: 1760px) {
    .component__header__top {
        padding-left: 150px;
        padding-right: 150px;
    }
}

@media screen and (max-width: 1531px) {
    .component__header__top {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media screen and (max-width: 1266px) {
    .component__header__top__nav {
        display: none;
        flex-direction: column;
        position: absolute;
        top: 0;
        left: 0;
        background: white;
        width: 100%;
        height: fit-content;
        z-index: 2;
        padding: 20px;
        align-items: flex-start;
        justify-content: flex-start;
        overflow: hidden;
        margin-left: 0;
        animation: animateNavbar .5s linear;

        @keyframes animateNavbar {
            0% {
                max-height: 0;
            }

            100% {
                max-height: 1000px;
            }
        }

        p {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.05em;
            color: #001B57;
            padding-bottom: 12px;
            border-bottom: 1px solid #D1D5DB;
            width: 100%;
            margin-top: 20px;
        }

        span {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 30px;

            #close {
                width: 12px;
                height: 12px;
            }
        }
    }

    .component__header {
        #hamburger {
            display: block;
        }
    }

    .component__header__top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}

@media screen and (max-width: 1140px){
    .component__header__bottom {
        display: none;
    }
}

@media screen and (max-width: 791px) {
    .component__header__bottom__nav {
        justify-content: space-between;
    }

    .component__header__bottom__nav p {
        margin-left: 0;
    }
}

@media screen and (max-width: 760px) {
    .component__header__top__search {
        width: 250px;
        margin-right: 0;
    }
}

@media screen and (max-width: 602px) {
    .component__header__top__search {
        width: 150px;
    }
}

@media screen and (max-width: 500px) {
    #desktopSearch {
        display: none;
    }

    .component__header__top__languageIcon {
        top: 85px;
        right: 20px;
    }

    #mobileSearch {
        display: flex;
        width: 240px;
        margin-left: 20px;
        margin-right: auto;
        margin-bottom: 29px;
    }

    .component__header__top {
        padding-top: 20px;
    }

    #logo {
        display: none;
    }

    .component__header #logomob {
        display: block;
    }

    .component__header__top__cart img,
    .component__header__top #user,
    .component__header #hamburger {
        width: 24px;
        height: 24px;
    }
}

@media screen and (max-width: 440px) {
    .component__header__userModal__box {
        z-index: 2;
        margin-top: 25%;
    }
}