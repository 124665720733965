.route__adminPanel {
    width: 100%;
    position: relative;

    &__deleteModal {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.432);
        margin-top: -260px;
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        animation: animateYesNo .5s linear;

        @keyframes animateYesNo {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }

        div {
            background: white;
            padding: 20px;
            box-shadow: 0 0 4px black;
            border-radius: 5px;
            position: absolute;
            top: 20%;

            h3 {
                text-align: center;
                color: #001B57;
                text-transform: uppercase;
                margin-bottom: 10px;
            }

            .daBtn {
                margin-right: 10px;
                height: fit-content;
                padding: 7px;
                border-radius: 3px;
            }

            .neBtn {
                height: fit-content;
                padding: 7px;
                border-radius: 3px;
            }
        }
    }

    &__login {
        width: fit-content;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        margin-top: 150px;
        box-shadow: 0 0 4px #001B57;
        padding: 20px;

        h1 {
            font-family: 'Open Sans';
            font-size: 25px;
            margin-bottom: 10px;
            color: #001B57;
        }

        input {
            width: 250px;
            height: 2rem;
            margin-bottom: 10px;
            padding: 5px;
            outline: none;
            border: 1px solid #001B57;
        }

        #msg {
            font-family: 'Open Sans';
        }
    }

    &__body {
        animation: animatePanel .5s linear;

        @keyframes animatePanel {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }

        display: grid;
        grid-template-columns: 20% 80%;
        min-height: 100vh;

        &__navbar {
            background: #2A3042;
            padding: 30px;

            #close {
                display: none;
            }

            h2 {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 24px;
                color: #FFFFFF;
                margin-bottom: 30px;
            }

            p {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 25px;
                color: #FFFFFF;
                margin-top: 24px;
                cursor: pointer;
            }
        }

        &__content {

            &__header {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
                width: 100%;
                padding: 15px;
                background: #FFFFFF;
                box-shadow: 0px 0px 137px rgba(0, 0, 0, 0.24);
                position: relative;

                #hamburger {
                    display: none;
                }

                span {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    margin-right: 10px;

                    img {
                        margin-right: 5px;
                        cursor: default;
                    }
                }

                img {
                    cursor: pointer;
                }

                div {
                    position: absolute;
                    top: 50px;
                    background: white;
                    padding: 20px;
                    right: 5px;
                    box-shadow: 0 0 4px gray;
                    border-radius: 5px;
                    animation: animateSettings .5s linear;
                    z-index: 1;
                    flex-direction: column;

                    @keyframes animateSettings {
                        0% {
                            opacity: 0;
                        }

                        100% {
                            opacity: 1;
                        }
                    }

                    .logoutBtn {
                        height: fit-content;
                        padding: 3px;
                        margin-top: 10px;
                    }

                    div {
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        img {
                            margin-left: auto;
                            margin-right: 0;
                        }

                        input {
                            margin-top: 10px;
                            outline: none;
                            padding: 5px;
                            border-radius: 5px;
                            border: 1px solid black;
                        }

                        #passMsg {
                            font-size: 12px;
                            margin-top: 5px;
                        }
                    }
                }
            }

            &__emails {
                padding: 50px;
                animation: animateEmails .5s linear;

                @keyframes animateEmails {
                    0% {
                        opacity: 0;
                    }

                    100% {
                        opacity: 1;
                    }
                }

                &__header {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;

                    h2 {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 22px;
                        line-height: 26px;
                        font-family: 'Open Sans';
                        color: #5E6366;
                    }

                    .addEmail {
                        background: #0458AD;
                        border-radius: 5px;
                        font-family: 'Open Sans';
                        width: 150px;
                        font-weight: 600;
                    }
                }

                &__newEmail {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    background: rgba(0, 0, 0, 0.247);
                    left: 0;
                    top: 0;
                    animation: animateMailModal .5s linear;

                    @keyframes animateMailModal {
                        0% {
                            opacity: 0;
                        }

                        100% {
                            opacity: 1;
                        }
                    }

                    #emailModal {
                        background: white;
                        border-radius: 5px;
                        box-shadow: 0 0 4px gray;
                        width: 1200px;
                        height: fit-content;
                        max-height: 600px;
                        padding: 20px;
                        margin-left: auto;
                        margin-right: auto;
                        margin-top: 10%;
                        overflow-y: scroll;
                        position: relative;

                        #mailmsg {
                            margin-top: 5px;
                            font-size: 14px;
                            color: red;
                        }

                        #spinner {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background: rgba(0, 0, 0, 0.192);
                            z-index: 100;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            padding-top: 12%;
                            animation: animateSpinner .5s linear;

                            @keyframes animateSpinner {
                                0% {
                                    opacity: 0;
                                }

                                100% {
                                    opacity: 1;
                                }
                            }
                        }

                        #ms {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                            margin-top: 5px;

                            #mh {
                                font-family: 'Open Sans';
                                font-style: normal;
                                font-weight: 700;
                                font-size: 19px;
                                line-height: 24px;
                                display: flex;
                                align-items: center;
                                color: #000000;
                                margin-top: 10px;
                            }

                            span {
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                justify-content: center;

                                p {
                                    font-size: 12px;
                                    margin-right: 5px;
                                    color: #0458AD;
                                    font-weight: 700;
                                }
                            }
                        }

                        .sendEmailBtn {
                            margin-top: 10px;
                            font-family: 'Open Sans';
                            font-weight: 700;
                            font-size: 15px;
                        }

                        #close {
                            margin-left: auto;
                            margin-right: 0;
                            display: block;
                            cursor: pointer;
                        }

                        #mp {
                            font-family: 'Open Sans';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 16px;
                            letter-spacing: 0.02em;
                            margin-top: 10px;
                            color: #6B7280;
                        }

                        input[type='text'] {
                            width: 100%;
                            height: 2rem;
                            margin-top: 10px;
                            outline: none;
                            padding: 5px;
                            background: #FFFFFF;
                            border: 1px solid #9CA3AF;
                            border-radius: 5px;
                        }
                    }
                }
            }

            &__opinions {
                padding: 50px;
                animation: animateOpinions .5s linear;

                @keyframes animateOpinions {
                    0% {
                        opacity: 0;
                    }

                    100% {
                        opacity: 1;
                    }
                }

                &__header {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;

                    h2 {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 22px;
                        line-height: 26px;
                        font-family: 'Open Sans';
                        color: #5E6366;
                    }

                    .addOpinion {
                        background: #0458AD;
                        border-radius: 5px;
                        font-family: 'Open Sans';
                        width: 150px;
                        font-weight: 600;
                    }
                }

                &__addNew {
                    position: absolute;
                    left: 0;
                    top: 0;
                    background: rgba(0, 0, 0, 0.301);
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    animation: animateOpinionModal .5s linear;

                    @keyframes animateOpinionModal {
                        0% {
                            opacity: 0;
                        }

                        100% {
                            opacity: 1;
                        }
                    }

                    #box {
                        background: white;
                        border-radius: 5px;
                        width: fit-content;
                        padding: 20px;
                        box-shadow: 0 0 4px gray;
                        margin-left: auto;
                        margin-right: auto;
                        display: flex;
                        flex-direction: column;
                        justify-content: baseline;
                        align-items: baseline;

                        #box-gridbox{
                            display: grid;
                            grid-template-columns: 1fr 1fr 1fr;
                            gap: 10px;
                        }

                        input::-webkit-file-upload-button {
                            visibility: hidden;
                        }

                        input::before {
                            content: "▲ Učitaj sliku";
                            color: #fff;
                            display: inline-block;
                            background: #001B57;
                            padding: 10px 22px;
                            outline: none;
                            white-space: nowrap;
                            cursor: pointer;
                            font-weight: 400;
                            border-radius: 2px;
                            outline: none;
                            box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
                        }

                        input:focus {
                            outline: none !important;
                        }

                        input:active::before {
                            transform: scale(.9) translate(0px, 2px);
                            box-shadow: inset 4px 4px 5px 0px rgba(0, 0, 0, 0.20);
                        }

                        input[type='file'] {
                            border: none;
                            width: 150px;
                            height: 55px;
                            margin-left: 0;
                            margin-right: auto;
                            display: block;
                        }

                        .addOpinionBtn {
                            margin-left: auto;
                            margin-right: 0;
                            font-family: 'Open Sans';
                            font-weight: 700;
                        }

                        #msg {
                            color: red;
                            font-weight: 700;
                            text-align: center;
                            font-size: 15px;
                        }

                        img {
                            cursor: pointer;
                            display: block;
                            margin-left: auto;
                            margin-right: 0;
                        }

                        textarea {
                            width: 500px;
                            outline: none;
                            padding: 5px;
                            background: #FFFFFF;
                            border: 1px solid #9CA3AF;
                            border-radius: 5px;
                            margin-top: 10px;
                            height: 5rem;
                        }

                        input {
                            width: 500px;
                            height: 2rem;
                            margin-top: 10px;
                            outline: none;
                            padding: 5px;
                            background: #FFFFFF;
                            border: 1px solid #9CA3AF;
                            border-radius: 5px;
                        }

                        h1 {
                            font-family: 'Open Sans';
                            font-style: normal;
                            font-weight: 700;
                            font-size: 19px;
                            line-height: 24px;
                            display: flex;
                            align-items: center;
                            color: #000000;
                            margin-top: 10px;
                        }

                        p {
                            margin-top: 10px;
                            font-family: 'Open Sans';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 16px;
                            letter-spacing: 0.02em;
                            color: #6B7280;
                            margin-top: 18px;
                        }
                    }
                }
            }

            &__products {
                padding: 50px;
                animation: animateProducts .5s linear;

                @keyframes animateProducts {
                    0% {
                        opacity: 0;
                    }

                    100% {
                        opacity: 1;
                    }
                }

                &__editProduct {
                    padding-bottom: 20px;
                    position: absolute;
                    z-index: 1;
                    background: rgba(0, 0, 0, 0.527);
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    animation: animateProducts .5s linear;

                    @keyframes animateProducts {
                        0% {
                            opacity: 0;
                        }

                        100% {
                            opacity: 1;
                        }
                    }

                    &__ingredients {
                        position: fixed;
                        top: 0;
                        left: 0;
                        background: rgba(0, 0, 0, 0.247);
                        width: 100%;
                        height: 100%;
                        animation: animateIng .3s linear;

                        @keyframes animateIng {
                            0% {
                                opacity: 0;
                            }

                            100% {
                                opacity: 1;
                            }
                        }

                        #ing {
                            background: white;
                            padding: 20px;
                            border-radius: 5px;
                            box-shadow: 0 0 4px gray;
                            display: flex !important;
                            flex-direction: column !important;
                            justify-content: center !important;
                            width: fit-content !important;
                            margin-left: auto;
                            margin-right: auto;
                            margin-top: 20%;

                            p {
                                font-size: 20px;
                                color: #000000;
                            }

                            div {
                                display: flex;
                                flex-direction: column;

                                .moreBtn {
                                    width: 20px;
                                    height: 20px;
                                    margin-top: 10px;
                                    margin-right: 0;
                                    margin-left: auto;
                                }
                            }

                            .saveBtn {
                                height: fit-content;
                                width: fit-content;
                                padding: 5px;
                                margin-top: 10px;
                            }

                            span {
                                display: flex;
                                flex-direction: row;
                                justify-content: baseline;
                                align-items: center;

                                input {
                                    width: 150px;
                                    margin-right: 10px;

                                    &:last-child {
                                        margin-right: 0;
                                    }
                                }
                            }
                        }
                    }

                    &__modal {
                        width: 1000px;
                        height: 900px;
                        overflow-y: scroll;
                        background: #FFFFFF;
                        border-radius: 5px;
                        padding: 40px;
                        display: block;
                        margin-left: auto;
                        margin-right: auto;
                        margin-top: 80px;

                        #grid {
                            display: grid;
                            grid-template-columns: 1fr 1fr;
                            gap: 10px;

                            #btns {
                                display: flex;
                                flex-direction: row;
                                justify-content: space-evenly;
                                align-items: flex-end;
                            }

                            div {
                                display: flex;
                                flex-direction: column;
                                justify-content: baseline;
                                align-items: baseline;

                                textarea {
                                    width: 100%;
                                    height: 5rem;
                                    margin-top: 10px;
                                    outline: none;
                                    padding: 5px;
                                    background: #FFFFFF;
                                    border: 1px solid #9CA3AF;
                                    border-radius: 5px;
                                }

                                .ingBtn {
                                    height: 2rem;
                                    width: 150px;
                                }
                            }
                        }

                        #diss {
                            display: flex;
                            flex-direction: column;
                        }

                        #picgrid {
                            display: grid !important;
                            grid-template-columns: 1fr 1fr 1fr;
                            place-items: center;
                            margin-bottom: 10px;
                            margin-top: 20px;

                            div {
                                display: flex;
                                flex-direction: column !important;
                                justify-content: center;
                                align-items: center;

                                p {
                                    margin-top: 0;
                                }
                            }
                        }

                        #pricing {
                            display: grid;
                            grid-template-columns: 1fr 1fr 1fr 1fr;
                            gap: 10px;
                        }

                        img {
                            display: block;
                            margin-left: auto;
                            margin-right: 0;
                            cursor: pointer;
                        }

                        h2 {
                            font-family: 'Open Sans';
                            font-style: normal;
                            font-weight: 700;
                            font-size: 19px;
                            line-height: 24px;
                            display: flex;
                            align-items: center;
                            color: #000000;
                        }

                        p {
                            font-family: 'Open Sans';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 16px;
                            letter-spacing: 0.02em;
                            color: #6B7280;
                            margin-top: 18px;
                        }

                        input {
                            width: 100%;
                            height: 2rem;
                            margin-top: 10px;
                            outline: none;
                            padding: 5px;
                            background: #FFFFFF;
                            border: 1px solid #9CA3AF;
                            border-radius: 5px;
                        }

                        input::-webkit-file-upload-button {
                            visibility: hidden;
                        }

                        input::before {
                            content: "▲ Učitaj sliku";
                            color: #fff;
                            display: inline-block;
                            background: #001B57;
                            padding: 10px 22px;
                            outline: none;
                            white-space: nowrap;
                            cursor: pointer;
                            font-weight: 400;
                            border-radius: 2px;
                            outline: none;
                            box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
                        }

                        input:focus {
                            outline: none !important;
                        }

                        input:active::before {
                            transform: scale(.9) translate(0px, 2px);
                            box-shadow: inset 4px 4px 5px 0px rgba(0, 0, 0, 0.20);
                        }

                        input[type='file'] {
                            border: none;
                            width: 150px;
                            height: 55px;
                            margin-left: 0;
                            margin-right: auto;
                            display: block;
                        }

                        div {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-evenly;

                            p {
                                font-weight: 700;
                                cursor: pointer;
                                padding: 3px;
                            }
                        }

                        .addBtn {
                            display: block;
                            margin-left: auto;
                            margin-right: auto;
                            font-family: 'Open Sans';
                            width: 320px;
                            height: 42px;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 24px;
                        }

                        #illness {
                            display: grid;
                            grid-template-columns: 1fr 1fr 1fr;
                            gap: 10px;

                            p {
                                font-weight: 700;
                                text-align: center;
                                background: #001B57;
                                color: white;
                                border-radius: 5px;
                                transition: 400ms;
                                text-transform: uppercase;

                                &:hover {
                                    background: #eb5757;
                                }
                            }
                        }
                    }
                }

                &__addNew {
                    padding-bottom: 20px;
                    position: absolute;
                    z-index: 1;
                    background: rgba(0, 0, 0, 0.527);
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;
                    padding-top: 50px;
                    animation: animateProducts .5s linear;

                    @keyframes animateProducts {
                        0% {
                            opacity: 0;
                        }

                        100% {
                            opacity: 1;
                        }
                    }

                    #errorMsg {
                        margin-top: 10px;
                        display: block;
                        margin-left: auto;
                        margin-right: auto;
                        text-align: center;
                        color: red;
                    }
                    &__modal {
                        width: 1000px;
                        height: 900px;
                        overflow-y: scroll;
                        background: #FFFFFF;
                        border-radius: 5px;
                        padding: 40px;
                        display: block;
                        margin-left: auto;
                        margin-right: auto;
                        position: relative;

                        &__ingredients {
                            position: fixed;
                            top: 0;
                            left: 0;
                            background: rgba(0, 0, 0, 0.247);
                            width: 100%;
                            height: 100%;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            animation: animateIng .3s linear;

                            @keyframes animateIng {
                                0% {
                                    opacity: 0;
                                }

                                100% {
                                    opacity: 1;
                                }
                            }

                            #ing {
                                background: white;
                                padding: 20px;
                                border-radius: 5px;
                                box-shadow: 0 0 4px gray;
                                display: flex !important;
                                flex-direction: column !important;
                                justify-content: center !important;
                                width: fit-content !important;
                                margin-left: auto;
                                margin-right: auto;
                                //margin-top: 50%;

                                p {
                                    font-size: 20px;
                                    color: #000000;
                                }

                                div {
                                    display: flex;
                                    flex-direction: column;

                                    .moreBtn {
                                        width: 20px;
                                        height: 20px;
                                        margin-top: 10px;
                                        margin-right: 0;
                                        margin-left: auto;
                                    }
                                }

                                .saveBtn {
                                    height: fit-content;
                                    width: fit-content;
                                    padding: 5px;
                                }

                                span {
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: baseline;
                                    align-items: center;

                                    input {
                                        width: 150px;
                                        margin-right: 10px;

                                        &:last-child {
                                            margin-right: 0;
                                        }
                                    }
                                }
                            }
                        }

                        #grid {
                            display: grid;
                            grid-template-columns: 1fr 1fr;
                            gap: 10px;

                            #btns {
                                display: flex;
                                flex-direction: row;
                                justify-content: space-evenly;
                                align-items: flex-end;
                            }

                            div {
                                display: flex;
                                flex-direction: column;

                                textarea {
                                    width: 100%;
                                    height: 5rem;
                                    margin-top: 10px;
                                    outline: none;
                                    padding: 5px;
                                    background: #FFFFFF;
                                    border: 1px solid #9CA3AF;
                                    border-radius: 5px;
                                }

                                .ingBtn {
                                    width: fit-content;
                                    height: 2rem;
                                    width: 150px;
                                }
                            }
                        }

                        #pricing {
                            display: grid;
                            grid-template-columns: 1fr 1fr 1fr 1fr;
                            gap: 10px;
                        }

                        #diss {
                            display: flex;
                            flex-direction: column;
                        }

                        img {
                            display: block;
                            margin-left: auto;
                            margin-right: 0;
                            cursor: pointer;
                        }

                        h2 {
                            font-family: 'Open Sans';
                            font-style: normal;
                            font-weight: 700;
                            font-size: 19px;
                            line-height: 24px;
                            display: flex;
                            align-items: center;
                            color: #000000;
                        }

                        p {
                            font-family: 'Open Sans';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 16px;
                            letter-spacing: 0.02em;
                            color: #6B7280;
                            margin-top: 18px;
                        }

                        input {
                            width: 100%;
                            height: 2rem;
                            margin-top: 10px;
                            outline: none;
                            padding: 5px;
                            background: #FFFFFF;
                            border: 1px solid #9CA3AF;
                            border-radius: 5px;
                        }

                        input::-webkit-file-upload-button {
                            visibility: hidden;
                        }

                        input::before {
                            content: "▲ Učitaj sliku";
                            color: #fff;
                            display: inline-block;
                            background: #001B57;
                            padding: 10px 22px;
                            outline: none;
                            white-space: nowrap;
                            cursor: pointer;
                            font-weight: 400;
                            border-radius: 2px;
                            outline: none;
                            box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
                        }

                        input:focus {
                            outline: none !important;
                        }

                        input:active::before {
                            transform: scale(.9) translate(0px, 2px);
                            box-shadow: inset 4px 4px 5px 0px rgba(0, 0, 0, 0.20);
                        }

                        input[type='file'] {
                            border: none;
                            width: 150px;
                            height: 55px;
                            margin-left: 0;
                            margin-right: auto;
                            display: block;
                        }

                        div {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-evenly;

                            p {
                                font-weight: 700;
                                cursor: pointer;
                                padding: 3px;
                            }
                        }

                        #picgrid {
                            display: grid !important;
                            grid-template-columns: 1fr 1fr 1fr;
                            place-items: center;
                            margin-bottom: 10px;

                            div {
                                display: flex;
                                flex-direction: column !important;
                                justify-content: center;
                                align-items: center;

                                p {
                                    margin-top: 0;
                                }
                            }
                        }

                        .addBtn {
                            display: block;
                            margin-left: auto;
                            margin-right: auto;
                            font-family: 'Open Sans';
                            width: 320px;
                            height: 42px;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 24px;
                        }

                        #illness {
                            display: grid;
                            grid-template-columns: 1fr 1fr 1fr;
                            gap: 10px;

                            p {
                                font-weight: 700;
                                text-align: center;
                                background: #001B57;
                                color: white;
                                border-radius: 5px;
                                transition: 400ms;
                                text-transform: uppercase;

                                &:hover {
                                    background: #eb5757;
                                }
                            }
                        }
                    }
                }

                &__productsList {
                    &__header {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;

                        h2 {
                            font-style: normal;
                            font-weight: 600;
                            font-size: 22px;
                            line-height: 26px;
                            font-family: 'Open Sans';
                            color: #5E6366;
                        }

                        .addNewBtn {
                            background: #0458AD;
                            border-radius: 5px;
                            font-family: 'Open Sans';
                            width: 150px;
                            font-weight: 600;
                        }
                    }
                }
            }

            &__couponCodes {
                padding: 50px;
                animation: animateCoupons .5s linear;

                @keyframes animateCoupons {
                    0% {
                        opacity: 0;
                    }

                    100% {
                        opacity: 1;
                    }
                }

                #select {
                    p {
                        font-size: 17px;
                        font-weight: 700;

                        span {
                            font-weight: 700;
                            margin-left: 10px;
                            margin-right: 10px;
                            border: 1px solid transparent;
                            padding: 3px;
                            cursor: pointer;
                        }
                    }
                }

                &__editCoupon {
                    padding-bottom: 20px;
                    position: absolute;
                    z-index: 1;
                    background: rgba(0, 0, 0, 0.527);
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    animation: animateCoupons .5s linear;

                    @keyframes animateCoupons {
                        0% {
                            opacity: 0;
                        }

                        100% {
                            opacity: 1;
                        }
                    }

                    #errorMsg {
                        margin-top: 10px;
                        display: block;
                        margin-left: auto;
                        margin-right: auto;
                        text-align: center;
                        color: red;
                    }

                    &__modal {
                        width: 750px;
                        height: fit-content;
                        background: #FFFFFF;
                        border-radius: 5px;
                        padding: 40px;
                        display: block;
                        margin-left: auto;
                        margin-right: auto;
                        margin-top: 80px;

                        #coupon-countires {
                            display: grid;
                            grid-template-columns: 1fr 1fr 1fr 1fr;
                            gap: 10px;
                        }

                        #select {
                            margin-top: 10px;

                            p {
                                font-size: 17px;
                                font-weight: 700;
                            }
                        }

                        img {
                            display: block;
                            margin-left: auto;
                            margin-right: 0;
                            cursor: pointer;
                        }

                        h2 {
                            font-family: 'Open Sans';
                            font-style: normal;
                            font-weight: 700;
                            font-size: 19px;
                            line-height: 24px;
                            display: flex;
                            align-items: center;
                            color: #000000;
                        }

                        p {
                            font-family: 'Open Sans';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 16px;
                            letter-spacing: 0.02em;
                            color: #6B7280;
                            margin-top: 18px;
                        }

                        input {
                            width: 100%;
                            height: 2rem;
                            margin-top: 10px;
                            outline: none;
                            padding: 5px;
                            background: #FFFFFF;
                            border: 1px solid #9CA3AF;
                            border-radius: 5px;
                        }

                        .addCouponBtn {
                            margin-top: 10px;
                        }
                    }
                }

                &__addNew {
                    padding-bottom: 20px;
                    position: absolute;
                    z-index: 1;
                    background: rgba(0, 0, 0, 0.527);
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    animation: animateCoupons .5s linear;

                    @keyframes animateCoupons {
                        0% {
                            opacity: 0;
                        }

                        100% {
                            opacity: 1;
                        }
                    }

                    #errorMsg {
                        margin-top: 10px;
                        display: block;
                        margin-left: auto;
                        margin-right: auto;
                        text-align: center;
                        color: red;
                    }

                    &__modal {
                        width: 750px;
                        height: fit-content;
                        background: #FFFFFF;
                        border-radius: 5px;
                        padding: 40px;
                        display: block;
                        margin-left: auto;
                        margin-right: auto;
                        margin-top: 80px;

                        #coupon-countires {
                            display: grid;
                            grid-template-columns: 1fr 1fr 1fr 1fr;
                            gap: 10px;
                        }

                        img {
                            display: block;
                            margin-left: auto;
                            margin-right: 0;
                            cursor: pointer;
                        }

                        h2 {
                            font-family: 'Open Sans';
                            font-style: normal;
                            font-weight: 700;
                            font-size: 19px;
                            line-height: 24px;
                            display: flex;
                            align-items: center;
                            color: #000000;
                        }

                        p {
                            font-family: 'Open Sans';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 16px;
                            letter-spacing: 0.02em;
                            color: #6B7280;
                            margin-top: 18px;
                        }

                        input {
                            width: 100%;
                            height: 2rem;
                            margin-top: 10px;
                            outline: none;
                            padding: 5px;
                            background: #FFFFFF;
                            border: 1px solid #9CA3AF;
                            border-radius: 5px;
                        }

                        .addCouponBtn {
                            margin-top: 10px;
                        }
                    }
                }

                &__couponsList {
                    &__header {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;

                        h2 {
                            font-style: normal;
                            font-weight: 600;
                            font-size: 22px;
                            line-height: 26px;
                            font-family: 'Open Sans';
                            color: #5E6366;
                        }

                        .addNewBtn {
                            background: #0458AD;
                            border-radius: 5px;
                            font-family: 'Open Sans';
                            width: 150px;
                            font-weight: 600;
                        }
                    }
                }
            }

            &__users {
                padding: 50px;
                animation: animateUsers .5s linear;

                @keyframes animateUsers {
                    0% {
                        opacity: 0;
                    }

                    100% {
                        opacity: 1;
                    }
                }

                &__header {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;

                    h2 {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 22px;
                        line-height: 26px;
                        font-family: 'Open Sans';
                        color: #5E6366;
                    }

                    .addUsrBtn {
                        background: #0458AD;
                        border-radius: 5px;
                        font-family: 'Open Sans';
                        width: 150px;
                        font-weight: 600;
                    }
                }

                &__addNewUserModal {
                    position: absolute;
                    left: 0;
                    top: 0;
                    background: rgba(0, 0, 0, 0.363);
                    width: 100%;
                    height: 100%;
                    z-index: 2;
                    animation: animateUsrModal .5s linear;

                    @keyframes animateUsrModal {
                        0% {
                            opacity: 0;
                        }

                        100% {
                            opacity: 1;
                        }
                    }

                    #box {
                        margin-top: 15%;
                        background: white;
                        width: fit-content;
                        padding: 20px;
                        margin-left: auto;
                        margin-right: auto;
                        border-radius: 5px;
                        box-shadow: 0 0 4px gray;

                        img {
                            margin-left: auto;
                            margin-right: 0;
                            display: block;
                            cursor: pointer;
                        }

                        h1 {
                            font-family: 'Open Sans';
                            font-style: normal;
                            font-weight: 700;
                            font-size: 19px;
                            line-height: 24px;
                            display: flex;
                            align-items: center;
                            color: #000000;
                        }

                        p {
                            font-family: 'Open Sans';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 16px;
                            letter-spacing: 0.02em;
                            color: #6B7280;
                            margin-top: 18px;
                        }

                        input {
                            width: 300px;
                            height: 2rem;
                            margin-top: 10px;
                            outline: none;
                            padding: 5px;
                            background: #FFFFFF;
                            border: 1px solid #9CA3AF;
                            border-radius: 5px;
                        }

                        .addBtn{
                            margin-top: 10px;
                            font-family: 'Open Sans';
                            font-weight: 700;
                            margin-right: 0;
                            margin-left: auto;
                            display: block;
                        }

                        div{
                            display: flex;
                            flex-direction: column;
                            margin-top: 10px;
                            span{
                                margin-top: 5px;
                                display: flex;
                                flex-direction: row;
                                justify-content: baseline;
                                align-items: center;
                                p{
                                    margin-top: 0;
                                    margin-left: 10px;
                                }
                                input{
                                    width: 14px;
                                    height: 14px;
                                    margin-top: 0;
                                }
                            }
                        }
                    }
                }
            }

            &__orders {
                padding: 50px;
                animation: animateOrders .5s linear;

                @keyframes animateOrders {
                    0% {
                        opacity: 0;
                    }

                    100% {
                        opacity: 1;
                    }
                }

                &__header {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;

                    h2 {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 22px;
                        line-height: 26px;
                        font-family: 'Open Sans';
                        color: #5E6366;
                    }

                    .addNewBtn {
                        background: #0458AD;
                        border-radius: 5px;
                        font-family: 'Open Sans';
                        width: 150px;
                        font-weight: 600;
                    }
                }

                #articles-table {
                    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                    gap: 10px;

                    #article {
                        background: #2A3042;
                        padding: 5px;
                        border-radius: 5px;

                        p {
                            color: white;

                            strong {
                                color: white;
                            }
                        }
                    }
                }
            }

            &__messages {
                padding: 50px;
                animation: animateMessages .5s linear;

                @keyframes animateMessages {
                    0% {
                        opacity: 0;
                    }

                    100% {
                        opacity: 1;
                    }
                }

                &__header {
                    h2 {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 22px;
                        line-height: 26px;
                        font-family: 'Open Sans';
                        color: #5E6366;
                    }
                }
            }

            &__createOrder {
                padding: 50px;
                animation: animateCreateOrder .5s linear;

                @keyframes animateCreateOrder {
                    0% {
                        opacity: 0;
                    }

                    100% {
                        opacity: 1;
                    }
                }

                &__header {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;

                    h2 {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 22px;
                        line-height: 26px;
                        font-family: 'Open Sans';
                        color: #5E6366;
                    }

                    .genOrderBtn {
                        border-radius: 5px;
                        font-family: 'Open Sans';
                        width: fit-content;
                        padding-left: 5px;
                        padding-right: 5px;
                        font-weight: 600;
                    }
                }

                &__generateNewOrderModal {
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;
                    width: 100%;
                    height: 100%;
                    background: rgba(0, 0, 0, 0.527);
                    animation: animateGenModal .5s linear;

                    @keyframes animateGenModal {
                        0% {
                            opacity: 0;
                        }

                        100% {
                            opacity: 1;
                        }
                    }

                    &__box {
                        min-width: 750px;
                        width: 750px;
                        margin-left: auto;
                        margin-right: auto;
                        margin-top: 10%;
                        background: white;
                        padding: 40px;
                        border-radius: 5px;
                        position: relative;

                        &__generated {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            background: #00000062;
                            left: 0;
                            top: 0;
                            z-index: 1;
                            animation: animateGen .5s linear;

                            @keyframes animateGen {
                                0% {
                                    opacity: 0;
                                }

                                100% {
                                    opacity: 1;
                                }
                            }

                            div {
                                width: 458px;
                                background: white;
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                                margin-left: auto;
                                margin-right: auto;
                                margin-top: 12%;
                                padding: 60px;
                                border-radius: 5px;

                                #close {
                                    display: block;
                                    margin-left: auto;
                                    margin-right: 0;
                                    cursor: pointer;
                                }

                                p {
                                    color: rgba(21, 25, 32, 0.5);
                                    text-align: center;
                                    margin-top: 20px;
                                }

                                h1 {
                                    font-family: 'Open Sans';
                                    text-align: center;
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 18px;
                                    line-height: 20px;
                                    display: flex;
                                    margin-top: 30px;
                                }
                            }
                        }

                        #closeicn {
                            display: block;
                            margin-left: auto;
                            margin-right: 0;
                            cursor: pointer;
                        }

                        .generateBtn {
                            width: 320px;
                            height: 42px;
                            display: block;
                            margin-left: auto;
                            margin-right: auto;
                            margin-top: 40px;
                            position: absolute;
                            bottom: 20px;
                        }

                        .clearBtn {
                            width: fit-content;
                            height: fit-content;
                            padding: 5px;
                            margin-top: 40px;
                            position: absolute;
                            bottom: 70px;
                            color: white;
                        }

                        &__gridbox {
                            display: grid;
                            grid-template-columns: 1fr 1fr;
                            gap: 20px;
                            margin-top: 40px;

                            p {
                                font-family: 'Open Sans';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 12px;
                                line-height: 16px;
                                letter-spacing: 0.02em;
                                color: #6B7280;
                                margin-bottom: 12px;
                            }

                            &__countryDropdown {
                                width: 320px;
                                height: 40px;
                                background: #FFFFFF;
                                border: 1px solid #9CA3AF;
                                border-radius: 5px;
                                padding-left: 19px;
                                padding-right: 19px;
                                font-size: 16px;
                                margin-bottom: 12px;
                                display: flex;
                                flex-direction: row;
                                justify-content: center;
                                align-items: center;
                                position: relative;

                                cursor: pointer;

                                p {
                                    font-size: 14px !important;
                                    color: black !important;
                                    width: 100%;
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: space-between;
                                    align-items: center;
                                    margin-bottom: 0 !important;

                                    img {
                                        width: 12px;
                                    }
                                }

                                div {
                                    height: fit-content !important;
                                    max-height: 320px;
                                    width: 320px;
                                    position: absolute;
                                    flex-direction: column;
                                    top: 35px;
                                    background: white;
                                    z-index: 1;
                                    padding-left: 19px;
                                    padding-right: 19px;
                                    padding-bottom: 10px;
                                    border-bottom: 1px solid #9CA3AF;
                                    border-left: 1px solid #9CA3AF;
                                    border-right: 1px solid #9CA3AF;
                                    border-radius: 0 0 5px 5px;
                                    overflow: hidden;
                                    overflow-y: scroll;
                                    animation: animateDropdown 1s linear;

                                    @keyframes animateDropdown {
                                        0% {
                                            max-height: 0px;
                                        }

                                        100% {
                                            max-height: 1000px;
                                        }
                                    }

                                    p {
                                        margin-top: 10px;
                                        font-size: 14px !important;

                                        &:hover {
                                            color: #001B57 !important;
                                        }
                                    }
                                }
                            }

                            &__dropdown {
                                width: 320px;
                                height: 40px;
                                background: #FFFFFF;
                                border: 1px solid #9CA3AF;
                                border-radius: 5px;
                                padding-left: 19px;
                                padding-right: 19px;
                                font-size: 16px;
                                margin-bottom: 12px;
                                display: flex;
                                flex-direction: row;
                                justify-content: center;
                                align-items: center;
                                position: relative;

                                cursor: pointer;

                                p {
                                    font-size: 14px !important;
                                    color: black !important;
                                    width: 100%;
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: space-between;
                                    align-items: center;
                                    margin-bottom: 0 !important;

                                    img {
                                        width: 12px;
                                    }
                                }

                                div {
                                    height: fit-content !important;
                                    max-height: 320px;
                                    width: 320px;
                                    position: absolute;
                                    flex-direction: column;
                                    top: 35px;
                                    background: white;
                                    z-index: 1;
                                    padding-left: 19px;
                                    padding-right: 19px;
                                    padding-bottom: 10px;
                                    border-bottom: 1px solid #9CA3AF;
                                    border-left: 1px solid #9CA3AF;
                                    border-right: 1px solid #9CA3AF;
                                    border-radius: 0 0 5px 5px;
                                    overflow: hidden;
                                    overflow-y: scroll;
                                    animation: animateDropdown 1s linear;

                                    @keyframes animateDropdown {
                                        0% {
                                            max-height: 0px;
                                        }

                                        100% {
                                            max-height: 1000px;
                                        }
                                    }

                                    p {
                                        margin-top: 10px;
                                        font-size: 14px !important;

                                        &:hover {
                                            color: #001B57 !important;
                                        }
                                    }
                                }
                            }

                            #col {
                                display: flex;
                                flex-direction: column;
                                justify-content: flex-start;

                                input {
                                    width: 320px;
                                    height: 40px;
                                    left: 625px;
                                    top: 521px;
                                    background: #FFFFFF;
                                    border: 1px solid #9CA3AF;
                                    border-radius: 5px;
                                    font-size: 12px;
                                    outline: none;
                                    padding-left: 19px;
                                }

                                #prepared-products {
                                    min-height: 300px;
                                    overflow-y: scroll;
                                    padding-right: 5px;
                                    margin-bottom: 30px;

                                    div {
                                        display: flex;
                                        flex-direction: row;
                                        justify-content: space-between;
                                        align-items: center;
                                        width: 100%;
                                        border-bottom: 1px solid black;
                                        margin-top: 10px;

                                        strong {
                                            font-size: 14px;
                                        }

                                        p {
                                            margin-bottom: 0;
                                            font-size: 14px;
                                            color: black;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1600px){
    .route__adminPanel__body__content__opinions__addNew #box #box-gridbox{
        grid-template-columns: 1fr 1fr;
        width: 100%;
    }
}

@media screen and (max-width: 1540px) {
    .route__adminPanel__body {
        grid-template-columns: 1fr;
        min-height: 0;
    }

    .route__adminPanel__body__navbar {
        height: fit-content;
    }

    .route__adminPanel__body__navbar {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;

        h2 {
            margin-bottom: 0;
        }

        p {
            margin-top: 0;
        }
    }

    .route__adminPanel__body__content {
        min-height: 1300px;
    }
}

@media screen and (max-width: 1247px) {
    .route__adminPanel__body__content__createOrder__generateNewOrderModal__box {
        margin-top: 20%;
    }

    .route__adminPanel__body__content__emails__newEmail #emailModal {
        width: 95%;
    }
}

@media screen and (max-width: 1070px) {
    .route__adminPanel__body__content__opinions__addNew #box{
        width: 95%;
    }

    .route__adminPanel__body__content__opinions__addNew #box #box-gridbox{
        grid-template-columns: 1fr;
    }
    .route__adminPanel__body__content__opinions__addNew #box{
        width: fit-content;
        max-height: 900px;
        overflow-y: scroll;
    }

    .route__adminPanel__body__content__couponCodes__addNew__modal {
        width: 95%;
    }

    .route__adminPanel__body__content__emails {
        padding: 20px;
    }

    .route__adminPanel__body__content__opinions {
        padding: 20px;
    }

    .route__adminPanel__body__content__createOrder__generateNewOrderModal__box {
        width: 95%;
        min-width: 95%;
    }

    .route__adminPanel__body__content__createOrder__generateNewOrderModal__box__gridbox {
        grid-template-columns: 1fr;
    }

    .route__adminPanel__body__content__createOrder__generateNewOrderModal__box .generateBtn {
        position: static;
        margin-top: 0;
    }

    .route__adminPanel__body__content__createOrder__generateNewOrderModal__box__gridbox #col {
        justify-content: center;
        align-items: center;
    }

    .route__adminPanel__body__content__createOrder__generateNewOrderModal__box .clearBtn {
        position: static;
        margin-top: 0;
        margin-bottom: 10px;
        margin-left: 0;
        margin-right: auto;
    }

    .route__adminPanel__body__content__createOrder__generateNewOrderModal__box__gridbox #col #prepared-products {
        width: 100%;
    }

    .customComponents__table__data__customInput div h3 {
        font-size: 14px !important;
    }

    .route__adminPanel__body__navbar {
        display: none;
        position: absolute;
        z-index: 3;
        top: 0;
        left: 0;
        width: 100%;
        flex-direction: column;
        justify-content: flex-start;
        align-items: baseline;
        overflow: hidden;
        animation: animateNavbar 1s linear;

        @keyframes animateNavbar {
            0% {
                max-height: 0;
            }

            100% {
                max-height: 1000px;
            }
        }

        p {
            margin-top: 10px;
        }

        #close {
            display: block;
            width: 14px;
            height: 14px;
            margin-left: auto;
            margin-right: 0;
        }
    }

    .route__adminPanel__body__content__header #hamburger {
        display: block;
        margin-left: 0;
        margin-right: auto;
    }

    .route__adminPanel__body__content__users {
        padding: 20px;
    }

    .route__adminPanel__body__content__products {
        padding: 20px;
    }

    .route__adminPanel__body__content__couponCodes {
        padding: 20px;
    }

    .route__adminPanel__body__content__couponCodes__couponsList__header .addNewBtn {
        width: 100px;
    }

    .route__adminPanel__body__content__orders {
        padding: 20px;
    }

    .route__adminPanel__body__content__createOrder {
        padding: 20px;
    }

    .route__adminPanel__body__content__createOrder__header h2 {
        font-size: 17px;
    }

    .route__adminPanel__body__content__messages {
        padding: 20px;
    }
}

@media screen and (max-width: 1022px) {
    .route__adminPanel__body__content__products__addNew__modal {
        width: 95%;
    }

    .route__adminPanel__body__content__products__editProduct__modal {
        width: 95%;
    }
}

@media screen and (max-width: 855px) {
    .route__adminPanel__body__content__emails__newEmail #emailModal {
        margin-top: 30%;
    }
    .route__adminPanel__body__content__users__addNewUserModal #box{
        margin-top: 30%;
    }
    .route__adminPanel__body__content__couponCodes__editCoupon__modal{
        width: 95%;
    }
}

@media screen and (max-width: 692px) {
    .route__adminPanel__body__content__products__editProduct__modal #grid {
        grid-template-columns: 1fr;
    }

    .route__adminPanel__body__content__products__editProduct__modal #grid #btns {
        margin-top: 20px;
    }

    .route__adminPanel__body__content__products__editProduct__modal #pricing {
        grid-template-columns: 1fr;
    }
    .route__adminPanel__body__content__opinions__addNew #box{
        width: 95%;
    }
    .route__adminPanel__body__content__opinions__addNew #box input, .route__adminPanel__body__content__opinions__addNew #box textarea{
        width: 100%;
    }
}

@media screen and (max-width: 550px) {
    .route__adminPanel__body__content__createOrder__generateNewOrderModal__box__generated div {
        width: 90%;
    }

    .route__adminPanel__body__content__products__editProduct__modal #picgrid {
        grid-template-columns: 1fr;
    }

    .route__adminPanel__body__content__products__addNew__modal #grid #btns {
        margin-top: 20px;
        justify-content: space-between;
        align-items: center;
    }

    .route__adminPanel__body__content__products__addNew__modal #grid div .ingBtn {
        margin-left: auto;
        margin-right: auto;
    }

    .route__adminPanel__body__content__products__addNew__modal #pricing {
        grid-template-columns: 1fr;
    }

    .route__adminPanel__body__content__couponCodes__addNew__modal #coupon-countires {
        grid-template-columns: 1fr;
    }

    .route__adminPanel__body__content__products__addNew__modal #grid {
        grid-template-columns: 1fr;
    }

    .route__adminPanel__body__content__products__addNew__modal #picgrid {
        grid-template-columns: 1fr;
        margin-top: 20px;
    }
}

@media screen and (max-width: 430px) {
    .route__adminPanel__body__content__createOrder__generateNewOrderModal__box{
        padding: 20px;
    }
    .route__adminPanel__body__content__products__editProduct__modal #illness{
        grid-template-columns: 1fr;
    }
    .route__adminPanel__body__content__opinions__header h2{
        font-size: 19px;
    }
    .route__adminPanel__body__content__header div{
        padding: 10px;
    }
    .route__adminPanel__body__content__header div .logoutBtn{
        width: 120px;
    }
    .route__adminPanel__body__content__createOrder__generateNewOrderModal__box__gridbox__dropdown {
        width: 250px;
    }
    .route__adminPanel__body__content__couponCodes__editCoupon__modal #coupon-countires{
        grid-template-columns: 1fr;
    }

    .route__adminPanel__body__content__products__addNew__modal {
        padding: 20px;
    }

    .route__adminPanel__body__content__products__editProduct__modal {
        padding: 20px;
    }

    .route__adminPanel__body__content__products__editProduct__modal .addBtn {
        width: 100%;
    }

    .route__adminPanel__body__content__createOrder__generateNewOrderModal__box__gridbox #col input {
        width: 100%;
    }

    .route__adminPanel__body__content__createOrder__generateNewOrderModal__box__gridbox__countryDropdown {
        width: 250px;
    }

    .route__adminPanel__body__content__createOrder__generateNewOrderModal__box .generateBtn {
        width: 100%;
    }

    .route__adminPanel__body__content__createOrder__generateNewOrderModal__box__gridbox__dropdown div {
        width: 101%;
    }

    .route__adminPanel__body__content__createOrder__generateNewOrderModal__box__gridbox__countryDropdown div {
        width: 101%;
    }

    .route__adminPanel__body__content__createOrder__generateNewOrderModal__box__gridbox #col {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        div{
            width: 100%;
        }
    }

    .route__adminPanel__body__content__products__addNew__modal #illness {
        grid-template-columns: 1fr;
    }

    .route__adminPanel__body__content__products__addNew__modal .addBtn {
        width: 100%;
    }
}