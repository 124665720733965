.route__termsOfService {
    padding-top: 50px;
    padding-left: 310px;
    padding-right: 310px;

    h1 {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        line-height: 43px;
        text-align: center;
        letter-spacing: 0.05em;
        text-transform: capitalize;
        color: #001B57;
    }

    p {
        margin-top: 12px;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        color: #5E6366;
    }
}

@media screen and (max-width: 1713px){
    .route__termsOfService{
        padding-left: 150px;
        padding-right: 150px;
    }
}

@media screen and (max-width: 1531px){
    .route__termsOfService{
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media screen and (max-width: 644px){
    .route__termsOfService h1{
        font-size: 28px;
    }
}

@media screen and (max-width: 500px){
    .route__termsOfService {
        padding-top: 0;
    }
    .route__termsOfService h1{
        font-size: 20px;
    }
}