.route__catalog {
    padding-left: 310px;
    padding-right: 310px;

    #headimg {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 40px;
        width: 100%;
        margin-bottom: 60px;
    }

    &__gridbox {
        display: grid;
        grid-template-columns: 20% 80%;

        .filtersBtn {
            display: none;
        }

        &__filters {
            padding-top: 33px;
            padding-right: 20px;

            h4 {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 19px;
                letter-spacing: 0.02em;
                text-transform: uppercase;
                color: #1F2937;
                margin-bottom: 20px;
            }

            &__dropdown {
                background: #0458AD;
                margin-bottom: 40px;
                color: white;
                padding: 5px;
                border-radius: 2px;
                position: relative;
                height: 40px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                cursor: pointer;

                p {
                    text-align: center;
                    font-weight: 700;
                    font-family: 'Open Sans';
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 12px;
                    padding-left: 10px;
                    padding-right: 10px;

                    img {
                        margin-top: 2px;
                        width: 10px;
                        height: 6px;
                        margin-left: 10px;
                    }
                }

                div {
                    border-top: 2px solid white;
                    flex-direction: column;
                    padding-top: 10px;
                    animation: animateDropdown 1s linear;
                    position: absolute;
                    top: 40px;
                    left: 0;
                    width: 100%;
                    z-index: 1;
                    background: #F5F5F5;
                    border-radius: 2px;
                    padding-bottom: 10px;
                    overflow: hidden;

                    @keyframes animateDropdown {
                        0% {
                            max-height: 0px;
                        }

                        100% {
                            max-height: 1000px;
                        }
                    }

                    p {
                        margin-top: 10px;
                        text-align: left;
                        transition: 400ms;
                        justify-content: flex-start;
                        padding-left: 0;
                        padding-top: 6px;
                        padding-bottom: 6px;
                        padding-left: 10px;
                        color: #1F2937;
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 16px;
                        letter-spacing: 0.02em;

                        &:hover {
                            background: #EAEBED;
                        }
                    }
                }
            }

            &__priceFilter {
                padding-bottom: 20px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                span {
                    display: flex;
                    flex-direction: row;
                    justify-content: baseline;
                    font-size: 14px;
                    margin-bottom: 0 !important;
                    max-height: 36px;

                    input {
                        display: block;
                        margin-left: auto;
                        margin-right: 0;
                        width: 90px !important;
                        outline: none;
                        padding: 6px;
                        height: 36px !important;
                        border: 1px solid #0458AD !important;
                        border-radius: 2px !important;
                    }
                }

                .updateBtn {
                    width: 36px;
                    height: 36px;
                    border-radius: 3px;
                    font-size: 10px;
                    font-family: 'Open Sans';
                    font-weight: 700;
                    background-image: url(../../../public/images/refresh.png);
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }

            #search {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 20px;

                #searchbar {
                    outline: none;
                    width: 80%;
                    padding: 3px;
                    height: 36px;
                    border: 1px solid #0458AD;
                    border-radius: 2px;
                }

                .updateBtn {
                    width: 36px;
                    height: 36px;
                    border-radius: 3px;
                    font-size: 10px;
                    font-family: 'Open Sans';
                    font-weight: 700;
                    background-image: url(../../../public/images/refresh.png);
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }

            span {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: baseline;
                margin-bottom: 12px;

                p {
                    margin-left: 12px;
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: 0.02em;
                    color: #1F2937;
                }

                input {
                    width: 14px;
                    height: 14px;
                    border: 1.12976px solid #b8b9bb;
                    border-radius: 5px;
                }
            }
        }

        &__products {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            row-gap: 34px;
            column-gap: 20px;

            #spinnerPag {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }
    }
}

@media screen and (max-width: 1753px) {
    .route__catalog {
        padding-left: 150px;
        padding-right: 150px;
    }
}

@media screen and (max-width: 1530px) {
    .route__catalog {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media screen and (max-width: 1267px) {
    .route__catalog__gridbox__filters {
        display: none;
        animation: animateFilters .5s linear;
        overflow: hidden;

        @keyframes animateFilters {
            0% {
                max-height: 0;
            }

            100% {
                max-height: 1000px;
            }
        }
    }
    .route__catalog__gridbox__filters__priceFilter{
        justify-content: flex-start;
    }
    .route__catalog__gridbox__filters span input{
        margin-right: 10px;
    }

    .route__catalog__gridbox__filters span {
        width: fit-content;
    }

    .route__catalog__gridbox__filters__dropdown {
        width: 300px;
    }

    .route__catalog__gridbox {
        grid-template-columns: 1fr;
    }

    .route__catalog__gridbox .filtersBtn {
        display: block;
        border: 1px solid #0458AD;
        border-radius: 5px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.02em;
        color: #0458AD;
        font-family: 'Open Sans';
        margin-bottom: 30px;
        width: 164px;
        height: 40px;
    }

    #prodnum {
        display: none;
    }
}

@media screen and (max-width: 994px) {
    .route__catalog__gridbox__products {
        grid-template-columns: 1fr 1fr 1fr;
        place-items: center;
    }
}

@media screen and (max-width: 522px){
    .route__catalog__gridbox__products {
        grid-template-columns: 1fr 1fr;
    }
}