.component__footer {
    color: white;
    margin-top: 70px;

    #copyrightmobile{
        display: none;
    }

    &__contact {
        padding-top: 30px;
        padding-bottom: 30px;
        padding-left: 310px;
        padding-right: 310px;
        display: grid;
        grid-template-columns: 32% 20% 20% 20%;
        place-items: center;
        gap: 40px;
        background: #292B30;
        font-family: 'Open Sans';
        position: relative;

        #mess-sent{
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 1;
            background: rgba(0, 0, 0, 0.719);
            animation: animateMsg .5s linear;

            @keyframes animateMsg{
                0%{
                    opacity: 0;
                }
                100%{
                    opacity: 1;
                }
            }
            
            h2{
                font-size: 22px;
                color: green;
                text-align: center;
                margin-top: 2%;
            }
        }

        .callMeBtn {
            width: 258px;
            height: 40px;
            font-style: normal;
            font-weight: 700;
            font-size: 15px;
            line-height: 18px;
            letter-spacing: -0.204545px;
            text-transform: uppercase;
            color: #FFFFFF;
            mix-blend-mode: normal;
            font-family: 'Open Sans';
        }

        p {
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            color: #FFFFFF;
        }

        input {
            width: 258px;
            height: 40px;
            border: 1px solid white;
            border-radius: 5px;
            background: transparent;
            padding-left: 27px;
            outline: none;
            color: white;

            &::placeholder {
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 18px;
                letter-spacing: -0.204545px;
                color: #FFFFFF;
                mix-blend-mode: normal;
            }
        }
    }

    &__main {
        padding-left: 310px;
        padding-right: 310px;
        padding-top: 66px;
        padding-bottom: 50px;
        display: grid;
        grid-template-columns: 50% 50%;
        gap: 100px;

        &__left {
            display: flex;
            flex-direction: column;
            justify-content: baseline;
            align-items: baseline;

            p {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 0.05em;
                color: #5E6366;
                margin-top: 82px;
            }

            img {
                margin-bottom: 50px;
            }
            &__distributors{
                &>p{
                    margin: 10px 0 !important;
                }
                
            }

            &__gridbox {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 10px;

                div {
                    display: flex;
                    flex-direction: column;
                    justify-content: baseline;
                    align-items: baseline;

                    strong {
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 19px;
                        letter-spacing: 0.05em;
                        text-transform: uppercase;
                        color: #001B57;
                        cursor: pointer;
                    }

                    p {
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 19px;
                        letter-spacing: 0.05em;
                        text-transform: capitalize;
                        color: #5E6366;
                        margin-top: 20px;
                        cursor: pointer;
                    }
                }
            }
        }

        &__right {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            p {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 10.5444px;
                line-height: 14px;
                letter-spacing: 0.05em;
                text-transform: uppercase;
                color: #5E6366;
            }

            span {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                margin-top: 12px;
                margin-bottom: 17px;

                img {
                    margin-right: 14px;
                }
            }

            &__subscribe {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 67%;
                margin-top: 14px;

                input {
                    width: 310px;
                    height: 36px;
                    background: #FFFFFF;
                    border: 0.753174px solid #5E6366;
                    border-radius: 5px;
                    padding-left: 12px;
                    outline: none;

                    &::placeholder {
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 16px;
                        letter-spacing: 0.05em;
                        color: #5E6366;
                    }
                }

                .subBtn {
                    width: 107px;
                    height: 36px;
                    background: #0458AD;
                    border-radius: 5px;
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: 0.05em;
                    text-transform: uppercase;
                    color: #FFFFFF;
                }
            }

            h4 {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 19px;
                letter-spacing: 0.05em;
                text-transform: uppercase;
                color: #464649;
                margin-top: 34px;
            }

            &__cards{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-top: 15px;
                width: 48%;
            }
        }
    }
}

@media screen and (max-width: 1713px){
    .component__footer__contact{
        padding-left: 150px;
        padding-right: 150px;
    }
    .component__footer__main{
        padding-left: 150px;
        padding-right: 150px;
    }
}

@media screen and (max-width: 1531px){
    .component__footer__contact{
        padding-left: 20px;
        padding-right: 20px;
    }
    .component__footer__main{
        padding-left: 20px;
        padding-right: 20px;
        gap: 0;
    }

    .component__footer__main__right{
        width: 100%;
    }
}

@media screen and (max-width: 1336px){
    .component__footer__contact .callMeBtn {
        width: 80%;
    }

    .component__footer__main__right__subscribe{
        width: 85%;
    }

    .component__header__bottom{
        padding-left: 20px;
        padding-right: 20px;
    }
    .component__footer__main__right__subscribe .subBtn{
        padding: 3px;
        margin-left: 10px;
    }
}

@media screen and (max-width: 1154px){
    .component__footer__contact{
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 957px){
    .component__footer__main__right__subscribe{
        width: 100%;
    }
    .component__footer__main__right__subscribe input{
        width: 80%;
    }
    .component__footer__main__right__subscribe .subBtn{
        width: fit-content;
    }
}

@media screen and (max-width: 756px){
    .component__footer__main{
        grid-template-columns: 1fr;
    }
    #copyright{
        display: none;
    }
    .component__footer #copyrightmobile{
        display: block;
        margin-top: 20px;
        text-align: center;
    }
    .component__footer__main__right{
        margin-top: 50px;
    }
}

@media screen and (max-width: 572px){
    .component__footer__main__left__gridbox{
        grid-template-columns: 1fr;
        place-items: center;
    }
    .component__footer__main__left p{
        margin-top: 20px;
    }
    .component__footer__main__right__subscribe input{
        width: 65%;
    }
    .component__footer__main__right__subscribe{
        width: 100%;
    }
    .component__footer__main__left{
        align-items: center;
    }
    .component__footer__main__right p{
        text-align: center;
        margin-top: 10px;
    }
    .component__footer__main__right span{
        justify-content: center;
    }
    .component__footer__main__right h4{
        text-align: center;
    }
    .component__footer__main__right__cards{
        width: 100%;
    }
    .component__footer__main__left__gridbox div{
        justify-content: center;
        align-items: center;
    }
    .component__footer__main__right span img:last-child{
        margin-right: 0;
    }
    .component__footer__main__left__gridbox div p{
        text-align: center;
    }
    .component__footer__main__right__subscribe .subBtn{
        padding-left: 3px;
        padding-right: 3px;
        margin-left: 10px;
    }
}