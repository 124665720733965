*{
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Mulish', sans-serif;
 }
 
 html,body, #root {
  max-width: 1927px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
 }
 
 ::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
}

::-webkit-scrollbar-thumb {
  background: rgb(85, 83, 83);
}

::-webkit-scrollbar-thumb:hover {
  background: #001B57;
}

 #root{
  overflow: auto;
 }