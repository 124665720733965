.route__whyNatural {
    width: 100%;
    padding-left: 310px;
    padding-right: 310px;

    &__gridbox {
        display: grid;
        grid-template-columns: 50% 50%;
        margin-top: 50px;

        &__left {
            h1 {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 36px;
                line-height: 43px;
                text-align: left;
                letter-spacing: 0.05em;
                text-transform: capitalize;
                color: #001B57;
                margin-bottom: 26px;
            }

            p {
                white-space: pre-wrap;
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                letter-spacing: 0.05em;
                color: #5E6366;
                width: 588px;
            }
        }
    }

    h2 {
        margin-top: 40px;
        text-align: center;
        font-style: normal;
        font-weight: 600;
        font-size: 26px;
        line-height: 31px;
        text-align: center;
        letter-spacing: 0.05em;
        font-family: 'Open Sans';
        color: #001B57;
    }

    #secimg {
        margin-top: 47px;
        width: 100%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .gotoBtn {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 638px;
        height: 60px;
        background: #0458AD;
        border-radius: 3px;
        font-family: 'Open Sans';
        font-weight: 700;
        margin-top: 50px;
        font-size: 18px;
    }
}

@media screen and (max-width: 1766px){
    .route__whyNatural{
        padding-left: 150px;
        padding-right: 150px;
    }
}

@media screen and (max-width: 1500px){
    .route__whyNatural{
        padding-left: 20px;
        padding-right: 20px;
        img{
            display: block;
            margin-left: auto;
            margin-right: 0;
        }
    }
}

@media screen and (max-width: 1200px){
    .route__whyNatural__gridbox{
        grid-template-columns: 1fr;
        img{
            order: 1;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 20px;
            width: 100%;
        }
        &__left{
            order: 2;
        }
    }
    .route__whyNatural__gridbox__left h1{
        text-align: center;
    }
}

@media screen and (max-width: 700px){
    .route__whyNatural .gotoBtn{
        width: 320px;
    }
}

@media screen and (max-width: 550px){
    .route__whyNatural__gridbox__left p{
        width: 100%;
        text-align: center;
    }
}