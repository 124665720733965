.route__aboutUs {
    padding-left: 310px;
    padding-right: 310px;
    padding-top: 50px;

    &__gridbox {
        display: grid;
        grid-template-columns: 40% 60%;

        img {
            margin-left: 157px;
        }

        div {
            h1 {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 36px;
                line-height: 43px;
                letter-spacing: 0.05em;
                color: #001B57;
            }

            p {
                white-space: pre-wrap;
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                letter-spacing: 0.05em;
                color: #5E6366;
                margin-top: 27px;
            }

            .aboutusBtn {
                width: 588px;
                height: 60px;
                border-radius: 3px;
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 22px;
                letter-spacing: 0.02em;
                text-transform: uppercase;
                color: #FFFFFF;
                margin-top: 27px;
            }
        }
    }
}

@media screen and (max-width: 1720px){
    .route__aboutUs{
        padding-left: 150px;
        padding-right: 150px;
    }
    .route__aboutUs__gridbox{
        grid-template-columns: 50% 50%;
    }
    .route__aboutUs__gridbox img{
        margin-left: 50px;
    }
}

@media screen and (max-width: 1500px){
    .route__aboutUs{
        padding-left: 20px;
        padding-right: 20px;
    }
    .route__aboutUs__gridbox img{
        margin-left: auto;
        margin-right: 0;
    }
}

@media screen and (max-width: 1200px){
    .route__aboutUs__gridbox{
        grid-template-columns: 1fr;
        div{
            order: 2;
        }
        img{
            order: 1;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

@media screen and (max-width: 616px) {
    .route__aboutUs__gridbox img{
        width: 100%;
    }
    .route__aboutUs__gridbox{
        width: 100%;
        div{
            margin-top: 20px;
            h1{
                text-align: center;
            }
            p{
                text-align: center;
            }
        }
    }
    .route__aboutUs__gridbox div .aboutusBtn{
        width: 320px;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }
}