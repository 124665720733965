.component__singleProduct {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr repeat(2, auto);
    place-items: center;
    cursor: pointer;
    width: 310px;
    height: 531px;
    position: relative;
    &__image{
        background-color: #ececec;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        border-radius: 2px;
        &>img{
            max-width: 70%;
            max-height: 70%;
            height: auto;
            width: auto;
        }
    }

    &__quantityModal{
        position: absolute;
        background: rgba(0, 0, 0, 0.295);
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        animation: animateQuantityModal .5s linear;

        @keyframes animateQuantityModal{
            0%{
                opacity: 0;
            }
            100%{
                opacity: 1;
            }
        }

        div{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background: white;
            height: fit-content;
            padding: 20px;
            border-radius: 10px;
            box-shadow: 0 0 4px gray;
            input{
                margin-top: 10px;
                margin-bottom: 10px;
                height: 1.5rem;
                width: 50px;
                padding-left: 5px;
                outline: none;
                border-radius: 5px;
                border: 1px solid black;
            }
            .addBtn{
                height: fit-content;
                padding: 5px;
            }
            p{
                font-size: 15px;
            }
        }
    }

    &__error {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        background: rgba(255, 0, 0, 0.205);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        animation: animateMsgg .5s linear;
        @keyframes animateMsgg{
            0%{
                opacity: 0;
            }
            100%{
                opacity: 1;
            }
        }
        p{
            text-align: center;
            margin-top: 70%;
            color: white;
            background: red;
            padding: 5px;
            font-weight: 700;
        }
    }

    

    &__description {
        width: 100%;
        margin-top: 20px;

        h3 {
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            font-family: 'Open Sans';
            letter-spacing: 0.02em;
            color: #001B57;
        }

        p {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.02em;
            color: #5E6366;
            margin-top: 7px;
        }

        span {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            align-items: center;
            img{
                width: 122px;
                height: 32px;
            }

            p {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 19px;
                text-align: right;
                letter-spacing: 0.02em;
                color: #012169;
            }
        }
    }

    &__price {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        margin-top: 21px;
        margin-left: 0;
        margin-right: auto;
        width: 100%;

        div {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            #discount {
                display: flex;

                p {
                    margin-right: 10px;
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 22px;
                    letter-spacing: 0.02em;
                    text-decoration-line: line-through;
                    color: #EB5757;
                }
            }

            #curPrice {
                display: flex;

                p {
                    margin-right: 10px;
                    margin-top: 8px;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 23px;
                    line-height: 28px;
                    font-family: 'Open Sans';
                    letter-spacing: 0.02em;
                    color: #001B57;
                }
            }
        }

        .Btn {
            width: 164px;
            height: 40px;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.02em;
            color: #FFFFFF;
        }
    }
}

@media screen and (max-width: 687px){
    .component__singleProduct{
        width: 150px;
    }
    .component__singleProduct img{
        width: 150px;
        height: 170px;
    }
    .component__singleProduct__description{
        width: 150px;
    }
    .component__singleProduct__price{
        flex-direction: column;
    }
    .component__singleProduct__price div{
        justify-content: flex-start;
        margin-left: 0;
        margin-right: auto;
    }
    .component__singleProduct__description span{
        flex-direction: column;
        justify-content: flex-start;
        align-items: baseline;
    }
    .component__singleProduct__price .Btn{
        width: 150px;
        margin-top: 8px;
    }
    .component__singleProduct__description{
        margin-top: 0;
    }
}

@media screen and (max-width: 433px){
    .component__singleProduct{
        width: 150px;
    }
    .component__singleProduct img{
        width: 100%;
    }
    .component__singleProduct__description span{
        justify-content: flex-start;
    }
}